import React, { useEffect, useMemo, useState } from "react";
import { protocol } from '@remix/websocket-protocols';
import { useParams } from "react-router-dom";
import tournamentProvider, { ITournament, TournamentStatus } from "../../../providers/tournaments/tournaments.provider";
import Loader from "../../../components/loader";
import SocketConnection from "../../../providers/tournaments/tournament.socket.provider";
import authProvider from "../../../providers/auth.provider";
import TournamentEliminationsView from "./sub-views/eliminations.view";
import TournamentBracketView from "./sub-views/bracket.view";
import appProvider from "../../../providers/app.provider";

const TournamentJudgeViewPage = () => {
  let { id } = useParams<"id">();
  const [ tournament, setTournamentData ] = useState<ITournament | null>(null);

  const ws = useMemo(() => {
    const ws = new SocketConnection();

    authProvider.fetchWsToken()
      .then(async (token) => {
        if (token !== null) {
          await ws.start('localhost', authProvider.user!.id, token, 13000);

          ws.registerHandler(protocol.S2C_Tournament_Refresh, (message) => {
            if (message.tournamentId !== id) {
              return;
            }

            tournamentProvider.fetchTournament(id as string)
              .then((data) => {
                setTournamentData(data);
              });
          });
        }
      });

    return ws;
  }, [id])

  useEffect(() => {
    appProvider.setTitle('Tournaments / Judge / ..');

    tournamentProvider.fetchTournament(id as string)
      .then((data) => {
        setTournamentData(data);
        appProvider.setTitle(`Tournaments / Judge / ${data.name}`);
      });
  }, [id]);

  if (!id || tournament === null) {
    return <Loader />;
  }
  
  return (
    <>
      {[TournamentStatus.STARTED, TournamentStatus.ELIMS_CLOSED].includes(tournament.status) &&
        <TournamentEliminationsView ws={ws} tournament={tournament} />
      }
      {tournament.status === TournamentStatus.BRACKETS_READY &&
        <TournamentBracketView ws={ws} tournament={tournament} />
      }
    </>
  );
}

export default TournamentJudgeViewPage;