import React, {memo, useEffect, useMemo, useRef, useState} from "react";

function fancyTimeFormat(duration: number) {
  // Hours, minutes and seconds
  const hrs = ~~((duration / 1000) / 3600);
  const mins = ~~(((duration / 1000) % 3600) / 60);
  const secs = ~~((duration / 1000) % 60);

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
}

const TimerDisplay: React.FC<{ startTime: string; seconds: number }> = memo(({ startTime, seconds }) => {
  const startTimeInt = Number.parseInt(startTime);
  const [timeLeft, setTimeLeft] = useState(seconds * 1000);
  const intervalRef = useRef<number>(-1); // Add a ref to store the interval id

  useMemo(() => {
    const step = () => {
      const diff = Date.now() - startTimeInt;
      let remaining = (seconds * 1000) - diff;

      if (remaining < 0) {
        remaining = 0;
        setTimeLeft(remaining);
        
        return;
      }

      setTimeLeft(remaining);
      requestAnimationFrame(step);
    };

    if (intervalRef.current === -1) {
      intervalRef.current = requestAnimationFrame(step);
    }
  }, [seconds, startTimeInt]);

  // Add a listener to `timeLeft`
  useEffect(() => {
    if (timeLeft <= 0) {
      cancelAnimationFrame(intervalRef.current);
    }
  }, [timeLeft]);

  return (
  <div className="absolute grid ml-[50%] -translate-x-1/2 w-full h-full rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark !bg-opacity-80 backdrop-blur-[6px] z-999">
    <div className="grid grid-cols-1 py-10 px-50 justify-center text-center content-center">
      <p className="font-bold text-6xl">Timer</p>
      <p className="font-bold text-9xl">{fancyTimeFormat(timeLeft)}</p>
    </div>
  </div>
  );
});

export default TimerDisplay;