import axios from 'axios';
import { Snowflake } from 'discord-api-types/v10'

export interface SettingsLogging {
  messageDelete?: Snowflake,
  messageEdit?: Snowflake,
  memberJoined?: Snowflake,
  memberLeave?: Snowflake,
  modActionLog?: Snowflake,
}

export type SettingsLoggingProviderType = {
  logging: SettingsLogging | null;
  fetchLoggingSettings: () => Promise<SettingsLogging>
  updateLoggingSettings: (formData: SettingsLogging) => Promise<void>
}

const api = axios.create({
  baseURL: '/api/guild/settings',
  timeout: 30_000,
  headers: {
    'Content-Type': 'application/json'
  }
});

const loggingProvider: SettingsLoggingProviderType = {
  logging: null,
  fetchLoggingSettings: async (): Promise<SettingsLogging> => {
    if (loggingProvider.logging) {
      return loggingProvider.logging;
    }

    const response = await api.get('/logging');

    loggingProvider.logging = response.data;

    return response.data as SettingsLogging;
  },
  updateLoggingSettings: async (formData: SettingsLogging): Promise<void> => {
    await api.post('/logging', formData);

    return;
  }
};

export default loggingProvider;