import React, { useEffect, useState } from "react";
import tournamentProvider, {ITournament} from "../../../providers/tournaments/tournaments.provider";
import HeaderBox from "../../../components/ui/containers/header.box";
import Checkbox from "../../../components/forms/checkbox.input";
import { PlusIcon } from "@heroicons/react/24/solid";
import tournamentConfigProvider, { PotentialHostJudge } from "../../../providers/tournaments/tournament.config.provider";
import JudgeHostLine from "./forms/judge-host-line.form";
import {useParams} from "react-router-dom";
import notificationProvider, { NotificationType } from "../../../providers/notification.provider";
import appProvider from "../../../providers/app.provider";
import Loader from "../../../components/loader";

const TournamentEditPage = () => {
  let { id } = useParams<"id">();
  const [ tournament, setTournamentData ] = useState<ITournament | null>(null);
  const [ possiblePeople, setPossiblePeople ] = useState<PotentialHostJudge[]>([]);

  useEffect(() => {
    appProvider.setTitle('Tournaments / Edit / ..');

    tournamentConfigProvider.getJudgesAndHosts()
      .then((data) => {
        setPossiblePeople(data);
      });

    tournamentProvider.fetchTournament(id as string)
      .then((data) => {
        setTournamentData(data);
        appProvider.setTitle(`Tournaments / Edit / ${data.name}`);
      });
  }, [id]);

  if (tournament === null) {
    return <Loader />;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await tournamentProvider.updateTournament(id as string, tournament)
      .then((id) => {
        notificationProvider.addNotification({
          title: 'Tournament Updated',
          body: `Successfully updated the tournament "${tournament.name}"`,
          type: NotificationType.SUCCESS
        });
        tournamentProvider.fetchTournament(id as string)
          .then((data) => {
            setTournamentData(data);
          });
      });
  };

  const addPersonRow = (event: any) => {
    event.preventDefault();

    const allPeople = [...tournament.people];

    allPeople.push({
      _id: '',
      userId: null as unknown as string,
      role: 'judge'
    });

    setTournamentData({
      ...tournament,
      people: allPeople
    });
  };

  const onUpdatePerson = (index: number, key: string, value: string | null) => {
    const allPeople = [...tournament.people];

    allPeople[index] = {
      ...allPeople[index],
      [key]: value,
    };

    setTournamentData({
      ...tournament,
      people: allPeople
    });
  };

  const onRemovePerson = (index: number) => {
    const allPeople = [...tournament.people].filter((_p, i) => i !== index);

    setTournamentData({
      ...tournament,
      people: allPeople
    });
  };

  const people = possiblePeople.map(c => {
    return {
      label: `${c.displayName} (${c.username})`,
      value: c.id,
    }
  });

  return (
    <>
      <form action="#" onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
          <div className="col-span-10">

            <HeaderBox header="Edit Tournament">
              <div className="p-6.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">

                <div className="col-span-6">
                  <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                    Name
                    <br />
                    <small>Publicly viewable name of the tournament.</small>
                  </label>
                  <input
                    name="name"
                    type="text"
                    required={true}
                    value={tournament.name}
                    onInput={(e) => setTournamentData({ ...tournament, name: e.currentTarget.value })}
                    className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none focus:transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                </div>

                <div className="col-span-6">
                  <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                    Number of Participants
                    <br />
                    <small>Number of people to make it through eliminations (Top 8/12/16 etc)</small>
                  </label>
                  <input
                    name="maxParticipants"
                    type="number"
                    required={true}
                    placeholder="8"
                    value={tournament.maxParticipants}
                    onInput={(e) => setTournamentData({ ...tournament, maxParticipants: e.currentTarget.valueAsNumber })}
                    className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none focus:transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                </div>

                <div className="col-span-12">
                  <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                    Small Final
                    <br />
                    <small>Whether there should be a small final to determine 3rd place</small>
                  </label>
                  <Checkbox value={tournament.thirdPlaceMatch} setValue={(value) => setTournamentData({ ...tournament, thirdPlaceMatch: value })} />
                </div>

                <div className="col-span-12">
                  <div className="border-b border-stroke pb-4 dark:border-strokedark">
                    <div className="flex items-start justify-between">
                      <h2 className="text-title-sm2 self-center font-bold text-black dark:text-white">
                        Judges + Host
                      </h2>

                      <button
                        className="button h-8 rounded bg-primary border-primary p-3 text-gray enabled:hover:bg-opacity-90 disabled:opacity-50"
                        onClick={addPersonRow}
                      >
                        <PlusIcon className="h-4 w-4" /> Add New
                      </button>
                    </div>
                  </div>

                  {tournament.people.map((person, index) => 
                    <JudgeHostLine key={`person_${index}`} index={index} people={people} person={person} onChange={onUpdatePerson} onRemove={onRemovePerson} />
                  )}
                </div>

                <div className="col-span-12">
                  <button type="submit" className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray enabled:hover:bg-opacity-90 disabled:opacity-50">
                    Save Tournament
                  </button>
                </div>
              </div>
            </HeaderBox>

          </div>

        </div>

      </form>
    </>
  );
}

export default TournamentEditPage;