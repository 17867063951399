import axios from 'axios';
import { Snowflake } from 'discord-api-types/globals';

export enum RoundLevel {
  UPPER,
  LOWER,
}
export enum RoundType {
  STANDARD,
  SEMIS,
  FINALS,
  TIEBREAKER,
}

export enum ParticipantResultOutcome {
  WIN,
  LOSS,
  DRAW
}
export type ParticipantResultType = {match: string; opponent: string; result: ParticipantResultOutcome}

export enum TournamentStatus {
  NOT_STARTED = 'not-started',
  STARTED = 'started',
  ELIMS_CLOSED = 'elims-closed',
  BRACKETS_READY = 'brackets-ready',
  FINISHED = 'finished',
  ARCHIVED = 'archived',
}

export interface TournamentMatchVote {
  _id: string;
  teamOne: boolean;
  teamTwo: boolean;
  draw: boolean;
  judgeId: Snowflake;
}

export interface TournamentMatch {
  _id: string;
  matchNumber: number;
  teamOne?: string;
  teamTwo?: string;
  active: boolean;
  teamOneWins: number;
  teamTwoWins: number;
  teamOneBye: boolean;
  teamTwoBye: boolean;
  draws: number;
  votes: TournamentMatchVote[];
  drawVotes: TournamentMatchVote[];
  winnerPath?: string;
  loserPath?: string;
  playOrder: number;
}

export interface TournamentRound {
  _id: string;
  sortRoundOrder: number;
  physicalRoundOrder: number;
  level: RoundLevel;
  type: RoundType;
  matches: Array<TournamentMatch>;
}

export interface TournamentParticipant {
  _id: string;
  name: string;
  userId: Snowflake;
  seed?: number;
  matchPoints: number;
  matches: number;
  byes: number;
  results: Array<ParticipantResultType>;
  active: boolean;
  joinedAt: Date;
}

export interface TournamentEliminationResult {
  _id: string;
  originality: number;
  musicality: number;
  clarity: number;
  complexity: number;
  judgeId: Snowflake;
}

export interface TournamentElimination {
  _id: string;
  name: string;
  userId: Snowflake;
  active: boolean;
  results: Array<TournamentEliminationResult>;
  joinedAt: Date;
  finalScore?: number;
}

export interface TournamentPeople {
  _id: string;
  userId: Snowflake;
  role: 'host' | 'judge';
  username?: string;
  displayName?: string;
}

export interface ITournament {
  id: string;
  name: string;
  roleId: Snowflake;
  thirdPlaceMatch: boolean;
  finalTiebreaker: boolean;
  maxParticipants: number;
  people: Array<TournamentPeople>;
  eliminationPosition: number;
  eliminationQueue: Array<TournamentElimination>;
  status: TournamentStatus;
  createdAt: Date;
  startedAt?: Date;
  finishedAt?: Date;
  deletedAt?: Date;
  cleanedUpAt?: Date;
  participants: Array<TournamentParticipant>;
  rounds: Array<TournamentRound>;
  values: {
    win: number;
    draw: number;
    loss: number;
  };
}

export type TournamentProviderType = {
  judgeCount: number,
  createTournament: (tournament: Pick<ITournament, 'name' | 'thirdPlaceMatch' | 'maxParticipants' | 'people'>) => Promise<string>
  updateTournament: (id: string, tournament: Pick<ITournament, 'name' | 'thirdPlaceMatch' | 'maxParticipants' | 'people'>) => Promise<string>
  fetchTournament: (id: string) => Promise<ITournament>
  startTournament: (id: string) => Promise<ITournament>
  closeEliminationsEntry: (id: string) => Promise<ITournament>
  removeElimination: (id: string, userId: string) => Promise<ITournament>
}

export const defaultTournament: Pick<ITournament, 'name' | 'thirdPlaceMatch' | 'maxParticipants' | 'people'> = {
  name: '',
  thirdPlaceMatch: true,
  maxParticipants: 8,
  people: [],
}

const api = axios.create({
  baseURL: '/api/guild',
  timeout: 30_000,
  headers: {
    'Content-Type': 'application/json'
  }
});

const tournamentProvider: TournamentProviderType = {
  judgeCount: 0,
  fetchTournament: async (id) => {
    const response = await api.get(`/tournaments/${id}`);

    tournamentProvider.judgeCount = response.data.judgeCount;

    return response.data.tournament;
  },
  createTournament: async (tournament) => {
    const response = await api.post('/tournaments/create', tournament);

    return response.data.id;
  },
  updateTournament: async (id, tournament) => {
    const response = await api.patch(`/tournaments/${id}/update`, tournament);

    return response.data.id;
  },
  startTournament: async (id) => {
    const response = await api.post(`/tournaments/${id}/start`);

    return response.data.tournament;
  },
  closeEliminationsEntry: async (id) => {
    const response = await api.post(`/tournaments/${id}/close-elims`);

    return response.data.tournament;
  },
  removeElimination: async (id, userId) => {
    const response = await api.delete(`/tournaments/${id}/elims/${userId}`);

    return response.data.tournament;
  },
};

export default tournamentProvider;