export enum ALIGNMENTS {
    LEFT = 0,
    MIDDLE = 1,
    RIGHT = 2,
}

export enum VERTICAL_ALIGNMENTS {
    TOP = 'top',
    MIDDLE = 'middle',
    BOTTOM = 'bottom',
}

export interface FontStyle {
    font: string
    colour: string
    x: number
    y: number
    kerning?: number
    alignment: ALIGNMENTS
    verticalAlignment?: VERTICAL_ALIGNMENTS
    stroke?: {
        width: number
        colour: string
    }
}