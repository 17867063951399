import React from "react";
import {TournamentElimination} from "../../../providers/tournaments/tournaments.provider";

export interface EliminationStandingTableRowProps {
  player: TournamentElimination;
  position: number;
}

const EliminationStandingTableRowComponent: React.FC<EliminationStandingTableRowProps> = ({ player, position }) => {
  const totalOriginality = player.results.reduce((pV, cV) => (cV.originality ?? 0) + pV, 0);
  const totalMusicality = player.results.reduce((pV, cV) => (cV.musicality ?? 0) + pV, 0);
  const totalClarity = player.results.reduce((pV, cV) => (cV.clarity ?? 0) + pV, 0);
  const totalComplexity = player.results.reduce((pV, cV) => (cV.complexity ?? 0) + pV, 0);
  const total = totalOriginality + totalMusicality + totalClarity + totalComplexity;

  return (
    <div className="grid grid-cols-2 md:grid-cols-6 border-b border-stroke dark:border-strokedark">
      <div className="flex items-center gap-3 p-2.5 xl:p-5">
        <span
          className="max-w-6.5 flex h-6.5 w-full items-center justify-center rounded-full bg-primary text-white">
          {position + 1}
        </span>
        <p className="text-black dark:text-white">{player.name}</p>
      </div>
      <div className="hidden md:flex  items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{totalOriginality}</p>
      </div>
      <div className="hidden md:flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{totalMusicality}</p>
      </div>
      <div className="hidden md:flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{totalClarity}</p>
      </div>
      <div className="hidden md:flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{totalComplexity}</p>
      </div>
      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{total}</p>
      </div>
    </div>
  )
}

export default EliminationStandingTableRowComponent;