import React, { useMemo } from "react";
import tournamentProvider, { ITournament } from "../../../../providers/tournaments/tournaments.provider";
import SocketConnection from "../../../../providers/tournaments/tournament.socket.provider";
import BracketCanvas from "../../../../components/tournaments/bracket-viewer/bracket-canvas.component";
import Tournament from "../../../../components/tournaments/bracket-viewer/data/Tournament";
import { EyeIcon } from "@heroicons/react/24/outline";
import { protocol } from "@remix/websocket-protocols";
import MatchResult from "./match-result.component";
import TimerComponent from "../../../../components/tournaments/timer.component";

interface IProps {
  ws: SocketConnection;
  tournament: ITournament;
}

const TournamentHostBracketView: React.FC<IProps> = ({ ws, tournament }) => {
  const [matchResult, setMatchResult] = React.useState<{ overtime?: boolean; winnerId?: string; winnerName?: string; } | null>(null);
  const judgeCount = tournamentProvider.judgeCount;
  const EliminationTournament = useMemo(() => {
    return new Tournament(tournament)
  }, [tournament]);

  const activeMatches = EliminationTournament.activeMatches();
  let activeMatch = null;

  if (activeMatches.length > 0) {
    activeMatch = activeMatches[0];
  }

  ws.registerHandler(protocol.S2C_Tournament_Match_Results, (message) => {
    if (message.tournamentId !== tournament.id) {
      return;
    }

    if (message.overtime) {
      setMatchResult({
        overtime: true
      });
      return;
    }

    setMatchResult({
      winnerName: message.winnerName as string,
      winnerId: message.winnerId as string,
    });
  });

  const handleViewResult = () => {
    ws.send(protocol.C2S_Tournament_Match_Results, {
      tournamentId: tournament.id,
    });
  }

  let votes = activeMatch?.doc.votes ?? [];

  if (activeMatch?.doc.draws ?? 0 > (activeMatch?.doc.teamOneWins ?? 0) + (activeMatch?.doc.teamTwoWins ?? 0)) {
    console.log(activeMatch?.doc);
    votes = activeMatch?.doc.drawVotes ?? [];
    console.log(votes);
  }

  let allJudgesVoted = false;

  if (votes.length === judgeCount) {
    allJudgesVoted = true;
  }

  return (
    <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
      {activeMatch && 
        <div className="col-span-12">
          <div className="rounded-sm relative border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <TimerComponent ws={ws} />

            {matchResult && <MatchResult {...matchResult} onClick={() => {
              setMatchResult(null)
            }} />}

            <div className="border-b border-stroke px-4 py-4 dark:border-strokedark md:px-6 md:py-6 xl:px-7.5">
              <h2 className="text-title-sm2 text-center font-bold text-black dark:text-white">
                {activeMatch?.longName}
              </h2>
            </div>
            <div className="grid grid-cols-3 gap-6 px-4 pt-4 pb-8 text-center font-bold text-4xl items-center">
              <div>{activeMatch?.teamOne?.doc.name}</div>
              <div className="text-8xl">vs</div>
              <div>{activeMatch?.teamTwo?.doc.name}</div>
            </div>

            <div className="grid grid-cols-1 justify-center gap-6 px-4 pt-4 pb-8 justify-items-center">
              <div>
                <button onClick={handleViewResult} disabled={!allJudgesVoted} className="float-right w-90 flex justify-center rounded bg-primary p-3 py-2 font-medium text-3xl text-gray enabled:hover:bg-opacity-90 disabled:opacity-50">
                  <EyeIcon className="w-10 h-10 mr-2" /> Show Results ({votes.length}/{judgeCount})
                </button>
              </div>
            </div>
          </div>

        </div>
      }

      <div className="col-span-12 pb-6">
        <BracketCanvas tournament={EliminationTournament} />
      </div>
    </div>
  );
}

export default TournamentHostBracketView;