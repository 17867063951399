import { Link } from "react-router-dom";

import BbxShackLogo from "../../ui/images/bbx-shack";

const ErrorPage = () => {
  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark appcontainer">     
      <div className="flex justify-center text-center w-screen h-screen overflow-hidden">
        <div className="w-1/2 flex flex-col justify-center items-center">
          <div className="flex-row">
            <BbxShackLogo className="w-full text-black-2 dark:text-white pb-15" />
          </div>
          <div className="flex-row">
            <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
              Whoops! Something went wrong.
            </h2>
          </div>
          <div className="flex-row pb-5">
            <p>Something has broken!</p>
          </div>

          <div className="flex-row">
            <Link to="/" reloadDocument className="flex items-center w-58 bg-[#5865F2] border border-slate-600 rounded-lg fill-white shadow-md px-6 py-2 text-md font-medium text-white hover:bg-[#4654f1] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;