import { Table } from "@tanstack/react-table";
import PageSizeSelector from "./PageSizeSelector";
type PaginateGotoProps<T> = {
  table: Table<T>;
  options: number[];
};

const PaginateGoto = <T,>({ table, options }: PaginateGotoProps<T>) => {
  return (
    <>
      <span className="flex items-center gap-1">
        Go to page:
        <input
          type="number"
          defaultValue={table.getState().pagination.pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            table.setPageIndex(page);
          }}
          className="page-selector"
          min="1"
          max={table.getPageCount()}
        />{" "}
        of {table.getPageCount()}
      </span>
      <PageSizeSelector table={table} options={options} />
    </>
  );
};

export default PaginateGoto;