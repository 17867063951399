import React, { useEffect, useRef, useState } from "react";
import { NavItem, loadNavItems } from "../../router/nav.items";
import SidebarItem from "./sidebar.item";
import { Link } from "react-router-dom";

import BbxShackLogo from "../../ui/images/bbx-shack";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const pathname = "";

  const [navItems, setNavItems] = useState<NavItem[]>([]);

  useEffect(() => {
    loadNavItems().then(setNavItems);
  }, [navItems]);

  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  let storedSidebarExpanded = localStorage.getItem('sidebar-expanded');

  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
  }, [sidebarExpanded]);

  const sidebarClasses = [];

  if (sidebarOpen) {
    sidebarClasses.push('translate-x-0');
  } else {
    sidebarClasses.push('-translate-x-full');
  }

  if (sidebarExpanded) {
    sidebarClasses.push('w-72.5');
  } else {
    sidebarClasses.push('w-32');
  }

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-9999 flex h-screen flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${sidebarClasses.join(' ')}`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
        <div className="flex justify-center">
          <Link to="/" className="w-9/12">
            <BbxShackLogo className="w-full fill-white" />
          </Link>
        </div>

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      {/* <!-- SIDEBAR HEADER --> */}

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        {/* <!-- Sidebar Menu --> */}
        <nav className={`mt-5 py-4 px-4 ${sidebarExpanded && 'lg:mt-9 lg:px-6'}`}>
          {/* <!-- Menu Group --> */}
          <div>
            <div className="grid grid-cols-12 justify-between  mb-4 ml-4">
              <h3 className="text-sm font-semibold text-bodydark2 col-span-10">
                MENU
              </h3>
              <XMarkIcon className={`w-5 col-span-2 ${!sidebarExpanded && 'hidden'}`} onClick={() => setSidebarExpanded(false)} />
            </div>

            <ul className="mb-6 flex flex-col gap-1.5">
              {navItems.map((item, index) => {
                return (
                  <SidebarItem
                    item={item}
                    key={index}
                    pathname={pathname}
                    sidebarExpanded={sidebarExpanded}
                    setSidebarExpanded={setSidebarExpanded}
                  />
                );
              })}
            </ul>
          </div>
        </nav>
        {/* <!-- Sidebar Menu --> */}
      </div>
    </aside>
  );
};

export default Sidebar;