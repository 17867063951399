import { useEffect, useState } from "react";
import notificationProvider, { INotification } from "../../providers/notification.provider";
import Notification from "./notification";

const NotificationContainer = () => {
  const [ notifications, setNotificationsData ] = useState<INotification[]>([]);

  useEffect(() => {
    setNotificationsData(notificationProvider.notifications);
    notificationProvider.setHookNotificationUpdate(setNotificationsData);
  }, []);

  return (
    <div className="fixed z-9999 top-20 flex flex-col items-center w-full max-w-md p-4 space-y-4 divide-y rtl:divide-y-reverse divide-gray-200 rounded-lg right-5 dark:divide-gray-700 space-y" role="alert">
      {notifications.map(notification => 
        <Notification key={notification.id} {...notification} />
      )}
    </div>
  );
};

export default NotificationContainer;