import "./index.css";
import {ButtonProps} from "./button-props.type";
import {memo} from "react";
import {CheckIcon} from "@heroicons/react/24/solid";

const styles = {
  primary: "bg-primary border-primary text-gray",
  info: "bg-info-dark border-info-dark text-gray",
  warning: "bg-warning-dark border-warning-dark text-gray",
  danger: "bg-danger-dark border-danger-dark text-gray"
}

const JudgeVoteButton = memo(({
  label,
  onClick,
  children,
  theme = "primary",
  hasVoted = false,
}: Omit<ButtonProps, 'disabled' | 'href'> & { hasVoted: boolean }) => {
  const buttonStyle = styles[theme];

  return (
    <button className={`float-right w-50 flex justify-center rounded ${buttonStyle} p-3 py-2 font-medium enabled:hover:bg-opacity-90 disabled:opacity-50`}
            onClick={onClick}>
        {children} {label} {hasVoted && <CheckIcon className="w-6 h-6 ml-2 text-success" />}
    </button>
  )
});

export default JudgeVoteButton;