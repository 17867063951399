import Participant from '../data/Participant.js';
import Tournament from '../data/Tournament.js';
import {ParticipantResultOutcome} from "../../../../providers/tournaments/tournaments.provider";

/**
 * Work for tiebreakers.
 * @namespace
 */
const Tiebreakers = {
  /**
   * Computes all tiebreakers for a team.
   * @param {Participant} team The team being processed.
   * @param {Tournament} tournament The current tournament.
   */
  compute: (team: Participant, tournament: Tournament) => {
    const calcCumulative = (team: Participant) => {
      let score = 0;
      let running = 0;
      team.doc.results.forEach((r) => {
        const inc =
          r.result === ParticipantResultOutcome.WIN
            ? tournament.doc.values.win
            : r.result === ParticipantResultOutcome.DRAW
              ? tournament.doc.values.draw
              : tournament.doc.values.loss;
        score += inc;
        running += score;
      });
      return running;
    };

    team.tiebreakers.cumulative = calcCumulative(team);
    team.tiebreakers.oppCumulative = team.doc.results.reduce((x, y) => {
      const op = tournament.participants.find((z) => z.doc._id === y.opponent);
      return x + (op ? calcCumulative(op) : 0);
    }, 0);
  },
  /**
   * Contains equality and difference functions for match points.
   * @type {Object}
   */
  matchpoints: {
    equal: (a: Participant, b: Participant) => a.doc.matchPoints === b.doc.matchPoints,
    diff: (a: Participant, b: Participant) => b.doc.matchPoints - a.doc.matchPoints,
  },
  /**
   * Contains equality and difference functions for the cumulative and cumulative opponent's tiebreakers.
   * @type {Object}
   */
  cumulative: {
    equal: (a: Participant, b: Participant) => {
      if (a.tiebreakers.cumulative === b.tiebreakers.cumulative) {
        if (a.tiebreakers.oppCumulative === b.tiebreakers.oppCumulative) {
          return true;
        }
        return 2;
      }
      return 1;
    },
    diff: (a: Participant, b: Participant, n?: number) =>
      n === 1
        ? b.tiebreakers.cumulative - a.tiebreakers.cumulative
        : b.tiebreakers.oppCumulative - a.tiebreakers.oppCumulative,
  },
  /**
   * Contains equality and difference functions for the versus tiebreaker.
   * @type {Object}
   */
  versus: {
    equal: (a: Participant, b: Participant) =>
      a.doc.results.filter((m) => m.opponent === b.doc._id).every((m) => m.result === ParticipantResultOutcome.DRAW),
    diff: (a: Participant, b: Participant) =>
      a.doc.results
        .filter((m) => m.opponent === b.doc._id)
        .reduce(
          (x, y) =>
            (x += y.result === ParticipantResultOutcome.WIN ? -1 : y.result === ParticipantResultOutcome.LOSS ? 1 : 0),
          0,
        ),
  },
};

export default Tiebreakers;
