import { RoundLevel, RoundType } from "../../../../providers/tournaments/tournaments.provider";
import Match from "../data/Match";
import Tournament from "../data/Tournament";
import { CANVAS, COLUMN, HEADER, MATCH } from "../styles";
import renderHeader from "./HeaderRender";
import renderMatch from "./MatchRender";
import roundRect from "./RoundRect";

const renderFn = async (tournament: Tournament, canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, overrideFill?: string) => {
  const upperRounds = tournament.getRounds().filter(r => r.doc.level === RoundLevel.UPPER)
  const lowerRounds = tournament.getRounds().filter(r => r.doc.level === RoundLevel.LOWER)
  const upperMatches = upperRounds.reduce((matches, round) => [...matches, ...round.matches], [] as Match[])
  const lowerMatches = lowerRounds.reduce((matches, round) => [...matches, ...round.matches], [] as Match[])
  const maxUpperMatchNumber = upperMatches.reduce((num, match) => match.doc.matchNumber > num ? match.doc.matchNumber : num, 0)
  const maxLowerMatchNumber = lowerMatches.reduce((num, match) => match.doc.matchNumber > num ? match.doc.matchNumber : num, 0)

  const fullTileHeight = MATCH.height + MATCH.margin.bottom

  canvas.width = (CANVAS.x * 2) + (COLUMN.width * (upperRounds.length > lowerRounds.length ? upperRounds.length : lowerRounds.length))
  canvas.height = (CANVAS.y * 2)
    + HEADER.height + HEADER.margin.bottom // header
    + ((fullTileHeight * maxUpperMatchNumber) - (maxLowerMatchNumber > 0 ? 0 : MATCH.margin.bottom))
    + (maxLowerMatchNumber > 0 ? HEADER.height + HEADER.margin.bottom : 0) // header
    + (maxLowerMatchNumber > 0 ? (fullTileHeight * maxLowerMatchNumber) - MATCH.margin.bottom : 0)

  context.fillStyle = overrideFill ?? CANVAS.fill
  roundRect(context, -5, -5, canvas.width + 10, canvas.height + 10, 0, true)

  const upperBracketHeight = (fullTileHeight * maxUpperMatchNumber)
  const lbColumnStart = ((fullTileHeight * maxUpperMatchNumber) - MATCH.margin.bottom) + HEADER.height + HEADER.margin.bottom + CANVAS.y + MATCH.margin.bottom
  const lowerBracketHeight = (fullTileHeight * maxLowerMatchNumber)

  // double loop is because logicalRound is not in perfect-sync
  for (const rounds of [upperRounds, lowerRounds]) {
    for (let i = 1; i <= rounds.length; i++) {
      const round = rounds[i - 1]
      const xPos = CANVAS.x + (i * COLUMN.width) - COLUMN.width
      let yPos = CANVAS.y,
        bracketHeight = upperBracketHeight,
        maxMatchNumber = maxUpperMatchNumber

      if (round.doc.level === RoundLevel.LOWER) {
        yPos = lbColumnStart
        bracketHeight = lowerBracketHeight
        maxMatchNumber = maxLowerMatchNumber
      }

      let headerText = `${round.doc.level === RoundLevel.LOWER ? 'LB ' : ''}Round ${i}`
      if (round.doc.type === RoundType.FINALS) {
        headerText = 'Finals'
      } else if (round.doc.type === RoundType.SEMIS) {
        headerText = 'Semifinals'
      } else if (round.doc.type === RoundType.TIEBREAKER) {
        headerText = 'Tiebreaker'
      }

      context.drawImage(await renderHeader(headerText), xPos, yPos)

      for (const match of round.matches) {
        const yChunkPosition = bracketHeight / round.matches.length

        let yPosition = Math.round(HEADER.height + HEADER.margin.bottom + yPos + (((yChunkPosition * match.doc.matchNumber) - (yChunkPosition / 2)) - (fullTileHeight / 2)))

        if (round.matches.length === maxMatchNumber) {
          yPosition = HEADER.height + HEADER.margin.bottom + yPos + (match.doc.matchNumber * fullTileHeight) - fullTileHeight
        }

        context.drawImage(await renderMatch(match), (xPos + (COLUMN.width / 2) - (MATCH.width / 2) - MATCH.padding.left), yPosition)

        if (!match.winnerPath || match.winnerPath.round.doc.level !== round.doc.level) {
          continue
        }

        const lineX = CANVAS.x + (i * COLUMN.width) + (MATCH.width / 2) - (COLUMN.width / 2)
        const lineY = yPosition + (MATCH.height / 2)

        const winnerMatchesInBracket = match.winnerPath.round.matches.length as number
        const winnerYChunkPosition = bracketHeight / winnerMatchesInBracket
        const lineToY = Math.round(HEADER.height + HEADER.margin.bottom + yPos + (((winnerYChunkPosition * match.winnerPath.doc.matchNumber) - (winnerYChunkPosition / 2) - (MATCH.margin.bottom / 2))))

        context.beginPath()
        context.lineWidth = 4
        context.strokeStyle = '#e3e3e3'
        context.moveTo(lineX, lineY)
        context.lineTo(lineX + MATCH.margin.right, lineY)
        context.lineTo(lineX + MATCH.margin.right, lineToY)
        context.lineTo(lineX + MATCH.margin.left + MATCH.margin.right, lineToY)
        context.stroke()
      }
    }
  }
};

export default renderFn;