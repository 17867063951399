import { useState } from "react";
import HeaderBox from "../../../../../components/ui/containers/header.box";
import automodProvider from "../../../../../providers/settings/settings.automod.provider";
import notificationProvider, { NotificationType } from "../../../../../providers/notification.provider";

interface SpamFormProps {
  mentions?: number;
  content?: number;
  scam?: number;
}

const SpamForm: React.FC<SpamFormProps> = ({
  mentions,
  content,
  scam
}) => {
  const [ mentionsValue, setMentionValues ] = useState(mentions ?? null);
  const [ contentValue, setContentValues ] = useState(content ?? null);
  const [ scamValue, setScamValues ] = useState(scam ?? null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    automodProvider.updateSettings({ spam: {
      mentions: mentionsValue ?? undefined,
      content: contentValue ?? undefined,
      scam: scamValue ?? undefined
    } }!)
      .then(() => {
        notificationProvider.addNotification({
          title: "Settings Saved",
          body: "Successfully updated spam settings.",
          type: NotificationType.SUCCESS
        });
      })
      .catch(() => {
        notificationProvider.addNotification({
          title: "An Error Occurred",
          body: "A problem occurred whilst attempting to save, please inform @tisryno on Discord",
          type: NotificationType.DANGER
        });
      });
  };

  return (
    <HeaderBox header="Spam Moderation">
      <form action="#" onSubmit={handleSubmit}>
        <div className="p-6.5">
          
          <div className="mb-4.5">
            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
              Mentions
              <br />
              <small>Maximum number of @'s to users, or @everyone/@here, within 60 seconds before action is taken.</small>
            </label>
            <input
              name="mentions"
              type="number"
              required={false}
              placeholder="10"
              value={mentionsValue ?? ''}
              onInput={(e) => setMentionValues(Number.isNaN(e.currentTarget.valueAsNumber) ? null : e.currentTarget.valueAsNumber)}
              className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
            />
          </div>
          
          <div className="mb-4.5">
            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
              Repeated Message
              <br />
              <small>Maximum number of repeated messages (across any channels), within 30 seconds before action is taken.</small>
            </label>
            <input
              name="content"
              type="number"
              required={false}
              placeholder="5"
              value={contentValue ?? ''}
              onInput={(e) => setContentValues(Number.isNaN(e.currentTarget.valueAsNumber) ? null : e.currentTarget.valueAsNumber)}
              className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
            />
          </div>

          <div className="mb-4.5">
            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
              Scam Link Detection
              <br />
              <small>Maximum number of times a Scam Link is detected, within 5 minutes before action is taken.</small>
            </label>
            <input
              name="scam"
              type="number"
              required={false}
              placeholder="3"
              value={scamValue ?? ''}
              onInput={(e) => setScamValues(Number.isNaN(e.currentTarget.valueAsNumber) ? null : e.currentTarget.valueAsNumber)}
              className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
            />
          </div>

          <button type="submit" className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray enabled:hover:bg-opacity-90 disabled:opacity-50">
            Save Settings
          </button>
        </div>
      </form>
    </HeaderBox>
  );
};

export default SpamForm;