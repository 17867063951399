import "./index.css";

import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  getFilteredRowModel,
  ColumnDef,
  ColumnSort,
  SortingState,
} from "@tanstack/react-table";

import Pagination from "./Pagination";
import { useMemo, useState } from "react";
import Filter from "./Filter";

type TableProps<TData> = {
  columns: ColumnDef<TData, any>[];
  data: TData[];
  defaultPaginationState?: PaginationState;
  defaultSortingState?: ColumnSort[];
  globalFilter?: {
    value: string;
    dispatch: (value: string) => void;
  };
  children?: React.ReactNode;
};

const TanstackTable = <TData,>({
  columns,
  data,
  defaultPaginationState,
  defaultSortingState,
  globalFilter,
  children,
}: TableProps<TData>) => {
  const [sorting, setSorting] = useState<SortingState>(
    defaultSortingState ?? []
  );
  const pagination = useMemo<PaginationState>(
    () => ({
      pageIndex: defaultPaginationState?.pageIndex ?? 0,
      pageSize: defaultPaginationState?.pageSize ?? 10,
    }),
    [defaultPaginationState?.pageIndex, defaultPaginationState?.pageSize]
  );
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination,
    },
    state: {
      sorting,
      globalFilter: globalFilter?.value,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: globalFilter?.dispatch,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
  });

  return (
    <section className="data-table-common data-table-primary-left-column">
      {children}
      <table className="datatable-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize(),
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="datatable-pagination">
        <Pagination
          table={table}
          pageRange={3}
          pageOptions={table.getPageOptions()}
        >
          <Pagination.Goto table={table} options={[10, 20, 30, 40, 50]} />
        </Pagination>
      </div>
    </section>
  );
};

TanstackTable.Filter = Filter;

export default TanstackTable;