import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import Select, { Props, ClearIndicatorProps, components, DropdownIndicatorProps, MultiValueRemoveProps } from "react-select";

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="w-4.5" />
    </components.DropdownIndicator>
  );
};
  
const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <XMarkIcon className="w-4.5" />
    </components.ClearIndicator>
  );
};
  
const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <XMarkIcon className="w-4.5" />
    </components.MultiValueRemove>
  );
};
  
const controlStyles = {
  base: "w-full rounded border-[1.5px] border-stroke bg-transparent px-2 py-1.5 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary",
  focus: "border-primary-600 ring-1 ring-primary-500",
  nonFocus: "border-gray-300 hover:border-gray-400",
};
const placeholderStyles = "text-gray-500 pl-1 py-0.5";
const selectInputStyles = "pl-1 py-0.5";
const valueContainerStyles = "p-1 gap-1";
const singleValueStyles = "leading-7 ml-1";
const multiValueStyles =
  "bg-gray-100 dark:bg-primary-dark rounded items-center py-0.5 pl-2 pr-1 gap-1.5";
const multiValueLabelStyles = "leading-6 py-0.5";
const multiValueRemoveStyles =
  "hover:text-red text-gray-500 dark:text-gray-100 hover:border-red-300 dark:hover:text-red";
const indicatorsContainerStyles = "p-1 gap-1";
const clearIndicatorStyles =
  "hover:text-red text-gray-500 dark:text-gray-100 hover:border-red-300 dark:hover:text-red";
const indicatorSeparatorStyles = "bg-gray-300 dark:bg-gray-700";
const dropdownIndicatorStyles =
  "p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black dark:hover:bg-gray-700 dark:text-gray-100";
const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg dark:bg-gray-900 dark:border-gray-600";
const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm font-bold dark:text-white";
const optionStyles = {
  base: "hover:cursor-pointer px-3 py-2 rounded hover:bg-gray-200 dark:hover:bg-gray-800",
  focus: "bg-gray-100 active:bg-gray-200 dark:bg-gray-900 dark:active:bg-gray-800",
  selected: "after:content-['✔'] after:ml-2 after:text-success text-gray-500 dark:text-white",
};
const noOptionsMessageStyles =
  "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";
  
const ReactSelect: React.FC<Props> = (props) => (
  <Select
    isMulti
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    unstyled
    styles={{
      input: (base) => ({
        ...base,
        "input:focus": {
          boxShadow: "none",
        },
      }),
      // On mobile, the label will truncate automatically, so we want to
      // override that behaviour.
      multiValueLabel: (base) => ({
        ...base,
        whiteSpace: "normal",
        overflow: "visible",
      }),
      control: (base) => ({
        ...base,
        transition: "none",
      }),
    }}
    components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
    classNames={{
      control: ({ isFocused }) =>
        clsx(
          isFocused ? controlStyles.focus : controlStyles.nonFocus,
          controlStyles.base,
        ),
      placeholder: () => placeholderStyles,
      input: () => selectInputStyles,
      valueContainer: () => valueContainerStyles,
      singleValue: () => singleValueStyles,
      multiValue: () => multiValueStyles,
      multiValueLabel: () => multiValueLabelStyles,
      multiValueRemove: () => multiValueRemoveStyles,
      indicatorsContainer: () => indicatorsContainerStyles,
      clearIndicator: () => clearIndicatorStyles,
      indicatorSeparator: () => indicatorSeparatorStyles,
      dropdownIndicator: () => dropdownIndicatorStyles,
      menu: () => menuStyles,
      groupHeading: () => groupHeadingStyles,
      option: ({ isFocused, isSelected }) =>
        clsx(
          isFocused && optionStyles.focus,
          isSelected && optionStyles.selected,
          optionStyles.base,
        ),
      noOptionsMessage: () => noOptionsMessageStyles,
    }}
    {...props}
  />
)

export default ReactSelect;