import { TournamentParticipant, ParticipantResultOutcome } from "../../../../providers/tournaments/tournaments.provider";

/** Class representing a team. */
export default class Participant {
    public readonly doc: TournamentParticipant
    public tiebreakers: { cumulative: number; oppCumulative: number; }

    /**
     * Create a new team.
     * @param {TournamentParticipant} document
     */
    constructor(document: TournamentParticipant) {
        this.doc = document

        /**
         * Tiebreaker values.
         * @type {Object}
         */
        this.tiebreakers = {
            cumulative: 0,
            oppCumulative: 0,
        }
    }

    get wins() {
        return this.doc.results.filter(r => r.result === ParticipantResultOutcome.WIN).length
    }

    get losses() {
        return this.doc.results.filter(r => r.result === ParticipantResultOutcome.LOSS).length
    }

    get draws() {
        return this.doc.results.filter(r => r.result === ParticipantResultOutcome.DRAW).length
    }
}
