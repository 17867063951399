import SocketConnection from "../../providers/tournaments/tournament.socket.provider";
import React, {memo, useEffect, useState} from "react";
import {protocol} from "@remix/websocket-protocols";
import TimerDisplay from "./timer-display.component";

export interface TimerComponentProps {
  ws: SocketConnection;
}

const TimerComponent: React.FC<TimerComponentProps> = memo(({ ws }) => {
  const [ timerEnabled, setTimerEnabled ] = useState<boolean>(false);
  const [ timerStartTime, setTimerStartTime ] = useState<string>('');
  const [ timer, setTimer ] = useState<number>(0);

  const handleTimerStart = (message: protocol.IS2C_Timer_Start) => {
    setTimer(message.timeAmount);
    setTimerStartTime(message.startTime);
    setTimerEnabled(true);
  };

  const handleTimerStop = () => {
    setTimer(0);
    setTimerEnabled(false);
  };

  useEffect(() => {
    ws.registerHandler(protocol.S2C_Timer_Start, handleTimerStart);

    ws.registerHandler(protocol.S2C_Timer_Stop, handleTimerStop);

    return () => {
      ws.unregisterHandler(protocol.S2C_Timer_Start, handleTimerStart);
      ws.unregisterHandler(protocol.S2C_Timer_Stop, handleTimerStop);
    }
  }, [ws]);

  return (
    <React.Fragment>
      {timerEnabled && <TimerDisplay startTime={timerStartTime} seconds={timer} />}
    </React.Fragment>
  )
});

export default TimerComponent;