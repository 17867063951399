import React, { useState } from "react";
import tournamentsListProvider, { type ITournamentListItem } from "../../../providers/tournaments/tournaments.list.provider";
import ReactQueryTable from "../../../components/tanstack-tables/react-query-table";
import { createColumnHelper } from "@tanstack/react-table";
import TableHeader from "../../../components/tanstack-tables/TableHeader";
import TanstackTable from "../../../components/tanstack-tables";
import { TableDataOptions } from "../../../components/tanstack-tables/Types/table-data-options.type";
import { TableDataResponse } from "../../../components/tanstack-tables/Types/table-data-response.type";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { format } from "date-fns";
import { AcademicCapIcon, XMarkIcon } from "@heroicons/react/24/solid";
import IconOnlyButton from "../../../components/buttons/icon-only.button";
import { ClockIcon, EyeIcon } from "@heroicons/react/24/outline";
import { TournamentStatus } from "../../../providers/tournaments/tournaments.provider";
import appProvider from "../../../providers/app.provider";
import authProvider from "../../../providers/auth.provider";

const columnHelper = createColumnHelper<ITournamentListItem>();

const columns = [
  columnHelper.accessor("name", {
    header: ({ column }) => <TableHeader title="Name" column={column} />,
  }),
  columnHelper.accessor("elimQueueCount", {
    header: ({ column }) => <TableHeader title="Participants" column={column} />,
  }),
  columnHelper.accessor("startedAt", {
    header: ({ column }) => <TableHeader title="Started At" column={column} />,
    cell: (info) => {
      if (info.getValue() !== undefined) {
        return format(info.getValue()!, 'Pp') 
      }

      return (
        <div className={`flex h-5 w-full max-w-5 items-center justify-center rounded-full bg-[#7F1D1D] text-white`}>
          <XMarkIcon className="w-4" />
        </div>
      );
    }
  }),
  columnHelper.accessor("finishedAt", {
    header: ({ column }) => <TableHeader title="Finished At" column={column} />,
    cell: (info) => {
      if (info.getValue() !== undefined && info.getValue() !== null) {
        return format(info.getValue()!, 'Pp') 
      }

      return (
        <div className="flex w-full max-w-6 h-6 justify-center rounded-full bg-[#7F1D1D] text-white">
          <XMarkIcon className="w-5 h-6" />
        </div>
      );
    }
  }),
  columnHelper.accessor("createdAt", {
    header: ({ column }) => <TableHeader title="Created At" column={column} />,
    cell: (info) => format(info.getValue(), 'Pp'),
  }),
  columnHelper.display({
    id: "actions",
    header: ({ column }) => <TableHeader title="Actions" column={column} />,
    cell: (props) => {
      const isJudge = props.row.original.people.find(person => person.userId === authProvider.user?.id && person.role === "judge");
      const isHost = props.row.original.people.find(person => person.userId === authProvider.user?.id && person.role === "host");
      const hasStarted = ![TournamentStatus.NOT_STARTED, TournamentStatus.ARCHIVED, TournamentStatus.FINISHED].includes(props.row.original.status);

      return (
        <div>
          <IconOnlyButton
            href={`/tournaments/${props.row.original.id}`}
            label="View Tournament"
          >
            <EyeIcon className="h-4 w-4" />
          </IconOnlyButton>
          {(hasStarted && isJudge) && <IconOnlyButton
            href={`/tournaments/${props.row.original.id}/judge`}
            label="Judge Tournament"
            theme="info"
          >
            <AcademicCapIcon className="h-4 w-4" />
          </IconOnlyButton>}
          {(hasStarted && isHost) && <IconOnlyButton
            href={`/tournaments/${props.row.original.id}/host`}
            label="Host Tournament"
            theme="info"
          >
            <ClockIcon className="h-4 w-4" />
          </IconOnlyButton>}
        </div>
      )
    },
    enableSorting: false,
    enableGlobalFilter: false,
  }),
];



const TournamentListPage = () => {
  const queryClient = new QueryClient();

  appProvider.setTitle('Tournaments / List');

  const [globalFilter, setGlobalFilter] = useState("");
  const handleFilter = (value: string | number) =>
    setGlobalFilter(String(value));
  const queryFn = async (
    params: TableDataOptions<ITournamentListItem, {}>
  ): Promise<TableDataResponse<ITournamentListItem>> => {
    return tournamentsListProvider.fetchTournaments();
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        
        <div className="col-span-12">

          <div className="rounded-sm relative border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke px-4 py-4 dark:border-strokedark md:px-6 md:py-6 xl:px-7.5">
              <div className="flex items-start justify-between">
                <h2 className="text-title-sm2 self-center font-bold text-black dark:text-white">
                  Tournaments
                </h2>
              </div>
            </div>
            <QueryClientProvider client={queryClient}>
              <ReactQueryTable
                columns={columns}
                defaultSortingState={[{ id: "name", desc: false }]}
                globalFilter={{
                  value: globalFilter,
                  dispatch: setGlobalFilter,
                }}
                queryFn={queryFn}
                uniqueResourceIdentifier="tournaments-list"
              >
                <TanstackTable.Filter
                  value={globalFilter}
                  onChange={handleFilter}
                  placeholder="Search"
                />
              </ReactQueryTable>
            </QueryClientProvider>
          </div>

        </div>
      </div>
    </>
  );
}

export default TournamentListPage;