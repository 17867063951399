import axios from 'axios';
import { Snowflake } from 'discord-api-types/globals';

export interface PotentialHostJudge {
  id: Snowflake;
  username: string;
  displayName: string;
}

export type TournamentConfigType = {
  getJudgesAndHosts: () => Promise<PotentialHostJudge[]>
}

const api = axios.create({
  baseURL: '/api/guild/tournaments',
  timeout: 30_000,
  headers: {
    'Content-Type': 'application/json'
  }
});

const tournamentConfigProvider: TournamentConfigType = {
    getJudgesAndHosts: async () => {
    const response = await api.get(`/config/judges-hosts`);

    return response.data.users;
  },
};

export default tournamentConfigProvider;