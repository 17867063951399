import { useState, useEffect, ComponentProps } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

type FilterProps = {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<ComponentProps<"input">, "value" | "onChange">;

const Filter = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: FilterProps) => {
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  useEffect(() => {
    setDebouncedValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(debouncedValue);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, debouncedValue, onChange]);

  return (
    <div className="datatable-filter-header">
      <div className="relative z-20 bg-white dark:bg-form-input flex w-80">
        <span className="absolute top-1/2 left-4 z-30 -translate-y-1/2">
          <MagnifyingGlassIcon className="w-6" />
        </span>
        <input
          {...props}
          className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 pl-12 pr-3 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input"
          value={debouncedValue}
          onChange={(e) => setDebouncedValue(e.target.value)}
        />
      </div>
    </div>
  );
};

export default Filter;