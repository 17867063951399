import { ALIGNMENTS, FontStyle } from './Constants'

export default async function writeText(
    styleObj: FontStyle,
    text: string,
    ctx: CanvasRenderingContext2D
): Promise<{ x1: number; y1: number; x2: number; y2: number; width: number; height: number }> {
    ctx.font = styleObj.font
    ctx.fillStyle = styleObj.colour

    if (styleObj.stroke) {
        ctx.lineWidth = styleObj.stroke.width
        ctx.strokeStyle = styleObj.stroke.colour
    }

    const metrics = ctx.measureText(text)
    const totalWidth = metrics.width + (styleObj.kerning ?? 0) * (text.length - 1)
    let posX = styleObj.x - Math.round(totalWidth / 2)
    ctx.textBaseline = styleObj.verticalAlignment ?? 'alphabetic'

    if (styleObj.alignment === ALIGNMENTS.LEFT) {
        posX = styleObj.x
    } else if (styleObj.alignment === ALIGNMENTS.RIGHT) {
        posX = styleObj.x - totalWidth
    }

    if (styleObj.kerning) {
        // We have some things to keep track of and the C programmer in me likes
        // declarations on their own and in groups.
        let offset,
            pair_width,
            char_width,
            char_next_width,
            pair_spacing,
            char,
            char_next,
            x = posX

        // We're going to step through the text one character at a time, but we
        // can't use for(... of ...) because we need to be able to look ahead.
        for (offset = 0; offset < text.length; offset = offset + 1) {
            // Easy on the eyes later
            char = text.charAt (offset)
            // Default the spacing between the "pair" of characters to 0. We need
            // for the last character.
            pair_spacing = 0
            // Check to see if there's at least one more character after this one.
            if (offset + 1 < text.length) {
                // This is always easier on the eyes
                char_next = text.charAt (offset + 1)
                // Measure to the total width of both characters, including the
                // spacing between them... even if it's negative.
                pair_width = ctx.measureText(char + char_next).width
                // Measure the width of just the current character.
                char_width = ctx.measureText(char).width
                // Measure the width of just the next character.
                char_next_width = ctx.measureText(char_next).width
                // We can determine the kerning by subtracting the width of each
                // character from the width of both characters together.
                pair_spacing = pair_width - char_width - char_next_width
            }

            // Draw the current character
            if (styleObj.stroke) {
                ctx.strokeText(char, x, styleObj.y)
            }
        
            ctx.fillText(char, x, styleObj.y)
            // Advanced the X position by adding the current character width, the
            // spacing between the current and next characters, and the manual
            // spacing adjustment (negatives work).
            x = x + (char_width ?? 0) + pair_spacing + styleObj.kerning
        }

        return {
            x1: posX,
            y1: styleObj.y,
            x2: posX + totalWidth,
            y2: styleObj.y + metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent,
            width: totalWidth,
            height: metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
        }
    }

    if (styleObj.stroke) {
        ctx.strokeText(text, posX, styleObj.y)
    }

    ctx.fillText(text, posX, styleObj.y)

    return {
        x1: posX,
        y1: styleObj.y,
        x2: posX + metrics.width,
        y2: styleObj.y + metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent,
        width: metrics.width,
        height: metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
    }
}