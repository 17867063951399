import { HEADER } from "../styles";
import { ALIGNMENTS, VERTICAL_ALIGNMENTS } from "./Constants";
import roundRect from "./RoundRect";
import writeText from "./WriteText";

const renderHeader = async (text: string) => {
  const headerCanvas = document.createElement('canvas');
  headerCanvas.width = HEADER.width;
  headerCanvas.height = HEADER.height;

  const headerCtx = headerCanvas.getContext('2d') as CanvasRenderingContext2D;

  headerCtx.lineWidth = HEADER.lineWidth
  headerCtx.strokeStyle = HEADER.stroke
  headerCtx.fillStyle = HEADER.fill
  roundRect(
    headerCtx,
    (HEADER.lineWidth / 2),
    (HEADER.lineWidth / 2),
    headerCanvas.width - HEADER.lineWidth,
    headerCanvas.height - HEADER.lineWidth,
    4,
    true,
    true
  )

  await writeText({
    font: HEADER.text.font,
    colour: HEADER.text.color,
    x: headerCanvas.width / 2,
    y: headerCanvas.height / 2,
    alignment: ALIGNMENTS.MIDDLE,
    verticalAlignment: VERTICAL_ALIGNMENTS.MIDDLE
  }, text, headerCtx)

  return headerCanvas
}

export default renderHeader;