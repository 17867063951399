import axios from 'axios'
import { PermissionFlagsBits, ChannelType } from 'discord-api-types/v10'
import { GroupBase } from 'react-select'

export interface GuildChannel {
  id: string
  name: string
  type: ChannelType
  position: number
  permissions: Array<keyof typeof PermissionFlagsBits>
  channels?: GuildChannel[]
}

export interface GuildEmoji {
  name: string;
  imageUrl: string;
  id: string;
  identifier: string;
}

export interface GuildRoles {
  id: string;
  name: string;
  colour: string;
  position: number;
}

export interface GuildResponse {
  bot: {
    name: string;
    avatar: string | null;
    presence: {
      status?: 'online' | 'idle' | 'dnd' | 'invisible',
      activities?: {
        emoji: string;
        name: string;
        type: string;
      }
    },
    permissions: Array<keyof typeof PermissionFlagsBits>
  },
  guild: {
    id: string;
    name: string;
    icon: string;
    members: number;
    roles: GuildRoles[],
    emojis: GuildEmoji[],
    channels: GuildChannel[]
  }
}

export type TextOnlyChannelSelectOption = GroupBase<{ label: string; value: string }>;

export type GuildProviderType = {
  guild: GuildResponse['guild'] | null;
  bot: GuildResponse['bot'] | null;
  fetchGuildStatistics: () => Promise<GuildResponse>;
  fetchTextOnlyChannelsAsSelectOptions: () => Promise<TextOnlyChannelSelectOption[]>;
}

const api = axios.create({
  baseURL: '/api/guild',
  timeout: 30_000,
  headers: {
    'Content-Type': 'application/json'
  }
});

const guildProvider: GuildProviderType = {
  guild: null,
  bot: null,
  fetchGuildStatistics: async (): Promise<GuildResponse> => {
    if (guildProvider.bot && guildProvider.guild) {
      return {
        guild: guildProvider.guild,
        bot: guildProvider.bot,
      };
    }

    const response = await api.get('/');

    guildProvider.bot = response.data.bot;
    guildProvider.guild = response.data.guild;

    return response.data as GuildResponse;
  },
  fetchTextOnlyChannelsAsSelectOptions: async () => {
    let channels = guildProvider.guild?.channels
    if (!channels) {
      const guild = await guildProvider.fetchGuildStatistics();

      channels = guild.guild.channels;
    }

    return channels.map(c => {
      return {
        label: c.name,
        options: c.channels?.filter(c =>
          c.type === 0 &&
          (c.permissions.includes('SendMessages') && c.permissions.includes('ViewChannel'))
        )?.map(channel => ({label: channel.name, value: channel.id})) ?? [],
      }
    })
  }
};

export default guildProvider;