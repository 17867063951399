import React from "react";
import tournamentProvider, { TournamentElimination } from "../../providers/tournaments/tournaments.provider";
import JudgeVoteInput from "./judge-vote-input.component";
import SocketConnection from "../../providers/tournaments/tournament.socket.provider";
import { protocol } from "@remix/websocket-protocols";
import { AuthUser } from "../../providers/auth.provider";
import { useRouteLoaderData } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";

interface IProps {
  tournamentId: string;
  user: TournamentElimination;
  isCurrent: boolean;
  ws: SocketConnection;
}

const EliminationQueueUser: React.FC<IProps> = ({ tournamentId, user, isCurrent, ws }) => {
  const { user: judge } = useRouteLoaderData("root") as { user: AuthUser | null };
  const judgeCount = tournamentProvider.judgeCount;

  let extraClasses = "";

  if (isCurrent) {
    extraClasses = "dark:bg-primary-dark bg-primary-light";
  }

  const vote = user.results.find(result => result.judgeId === judge?.id)
  const votesCast = user.results.filter(result => 
    typeof result.clarity === 'number' &&
    typeof result.complexity === 'number' &&
    typeof result.musicality === 'number' &&
    typeof result.originality === 'number' 
  )

  const sendVote = (fieldName: string, value?: number) => ws.send(protocol.C2S_Tournament_SubmitVote, { tournamentId, userId: user._id, fieldName, value })

  const debounceOriginality = useDebouncedCallback((value?: number) => sendVote('originality', value), 800);
  const debounceMusicality = useDebouncedCallback((value?: number) => sendVote('musicality', value), 800);
  const debounceClarity = useDebouncedCallback((value?: number) => sendVote('clarity', value), 800);
  const debounceComplexity = useDebouncedCallback((value?: number) => sendVote('complexity', value), 800);

  return (
    <li className={`border-stroke dark:border-strokedark ${extraClasses}`}>
      <div className="grid grid-cols-6 gap-2 items-center justify-between py-2 px-4">
        <div className="text-black dark:text-white md:truncate">{user.name}<br /><small>({user.userId})</small></div>
        <div>
          <JudgeVoteInput
            userId={user._id}
            fieldName="originality"
            defaultValue={vote?.originality}
            onInput={
              (value?: number) => debounceOriginality(value)}
          />
        </div>
        <div>
          <JudgeVoteInput
            userId={user._id}
            fieldName="musicality"
            defaultValue={vote?.musicality}
            onInput={(value?: number) => debounceMusicality(value)}
          />
        </div>
        <div>
          <JudgeVoteInput
            userId={user._id}
            fieldName="clarity"
            defaultValue={vote?.clarity}
            onInput={(value?: number) => debounceClarity(value)}
          />
        </div>
        <div>
          <JudgeVoteInput
            userId={user._id}
            fieldName="complexity"
            defaultValue={vote?.complexity}
            onInput={(value?: number) => debounceComplexity(value)}
          />
        </div>

        <div className="pl-2 flex">
          <div>{votesCast.length}/{judgeCount}</div>
          <div className="pl-2">
            {(votesCast.length === judgeCount) ? <CheckCircleIcon className="w-6 h-6 text-success" /> : <XCircleIcon className="w-6 h-6 text-danger" />}
          </div>
        </div>
      </div>
    </li>
  );
}

export default EliminationQueueUser;