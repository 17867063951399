import { ITournament } from "../../../../providers/tournaments/tournaments.provider";
import Match from "./Match";
import Participant from "./Participant";
import Round from "./Round";
import RoundMap from "./collections/RoundMap";
import Tiebreakers from "../lib/Tiebreakers";

export type TieBreakTypes = 'cumulative' | 'versus' | 'match-points'

export default class Tournament {
  public readonly doc: ITournament

  public participants: Participant[] = []
  public rounds: RoundMap = new RoundMap()

  protected bestOf = 1
  protected tiebreakers: TieBreakTypes[] = []

  constructor(doc: ITournament) {
    this.doc = doc;

    for (const participant of this.doc.participants) {
      this.participants.push(new Participant(participant));
    }

    for (const round of this.doc.rounds) {
      this.rounds.set(round._id.toString(), new Round(round, this));
    }

    if (this.rounds.size > 0) {
      this.getMatches().forEach(m => m.fillMatchData());
    }
  }

  /**
   * Get the active matches in the tournament.
   * If no round is specified, it returns all active matches for all rounds.
   * @param {?Number} round Optional round selector.
   * @return {Match[]}
   */
  activeMatches(round: number | null = null): Match[] {
    const matches: Match[] = round === null
      ? Array.from(this.rounds.values())
        .reduce((matches, round) => [...matches, ...round.matches], [] as Match[])
        .filter((m: Match) => m.doc.active)
      : (this.rounds.getByRound(round) as Round).matches.filter(m => m.doc.active)

    return matches.sort((a, b) => {
      if (a.round === b.round) {
        return a.doc.matchNumber - b.doc.matchNumber
      }

      return a.round.doc.sortRoundOrder - b.round.doc.sortRoundOrder
    })
  }

  getRounds() {
    return Array.from(this.rounds.values())
  }

  getMatches(): Match[] {
    return this.getRounds().reduce((matches, round) => [...matches, ...round.matches], [] as Match[])
  }

  /**
   * Get the current standings of the tournament.
   * @param {Boolean} [active=true] Filtering only active teams.
   * @return {Participant[]}
   */
  standings(active = true): Participant[] {
    this.participants.forEach((p) => Tiebreakers.compute(p, this));

    const theseTeams = active ? this.participants.filter((p) => p.doc.active) : [...this.participants];

    theseTeams.sort((a, b) => {
      for (let i = 0; i < this.tiebreakers.length; i++) {
        const prop = this.tiebreakers[i].replace('-', '') as keyof Omit<typeof Tiebreakers, 'compute'>;
        const eqCheck = Tiebreakers[prop].equal(a, b);

        if (eqCheck === true) {
          if (i === this.tiebreakers.length - 1) {
            return Math.random() * 2 - 1;
          }
          continue;
        } else if (typeof eqCheck === 'number') {
          return Tiebreakers[prop].diff(a, b, eqCheck);
        }

        return Tiebreakers[prop].diff(a, b);
      }

      return Tiebreakers.matchpoints.diff(a, b);
    });

    return theseTeams;
  }
}