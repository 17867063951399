import React from "react";
import Participant from "../bracket-viewer/data/Participant";
import StandingTableRowComponent from "./standing-table-row.component";

export interface StandingsTableComponentProps {
  standings: Participant[];
}

const StandingsTableComponent: React.FC<StandingsTableComponentProps> = ({ standings }) => {
  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-4 rounded-sm bg-gray-2 dark:bg-meta-4">
        <div className="p-2.5 xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base"># / Name</h5>
        </div>
        <div className="p-2.5 text-center xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">Wins</h5>
        </div>
        <div className="p-2.5 text-center xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">Losses</h5>
        </div>
        <div className="p-2.5 text-center xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">Draws</h5>
        </div>
      </div>

      {standings.map((player, i) => <StandingTableRowComponent player={player} key={`player_${i}`} position={i} />)}
    </div>
  );
}

export default StandingsTableComponent;