import axios from 'axios';
import { TableDataResponse } from '../../components/tanstack-tables/Types/table-data-response.type';
import {TournamentPeople, TournamentStatus} from './tournaments.provider';

export interface ITournamentListItem {
  id: string;
  name: string;
  elimQueueCount: number;
  status: TournamentStatus;
  createdAt: Date;
  startedAt?: Date;
  finishedAt?: Date;
  people: TournamentPeople[],
}

export type TournamentsListProviderType = {
  tournaments: ITournamentListItem[];
  fetchTournaments: () => Promise<TableDataResponse<ITournamentListItem>>
}

const api = axios.create({
  baseURL: '/api/guild',
  timeout: 30_000,
  headers: {
    'Content-Type': 'application/json'
  }
});

const tournamentsListProvider: TournamentsListProviderType = {
  tournaments: [],
  fetchTournaments: async () => {
    const response = await api.get('/tournaments');

    tournamentsListProvider.tournaments = response.data.tournaments;

    return {
      data: response.data.tournaments,
      meta: {
        hasNextPage: false,
        totalRecordCount: response.data.tournaments.length
      }
    }
  },
};

export default tournamentsListProvider;