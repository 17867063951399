import { Link } from "react-router-dom";
import "./index.css";
import { ButtonProps } from "./button-props.type";

const styles = {
  primary: "bg-primary border-primary text-gray",
  info: "bg-info-dark border-info-dark text-gray",
  warning: "bg-warning-dark border-warning-dark text-gray",
  danger: "bg-danger-dark border-danger-dark text-gray"
}

const IconOnlyButton = ({
  label,
  onClick,
  children,
  href = "#",
  theme = "primary",
  disabled,
}: ButtonProps) => {
  const buttonStyle = styles[theme];
  if (onClick) {
    return (
      <button className={`button rounded icon-only h-8 group ${buttonStyle} mr-1.5 hover:bg-opacity-90`} onClick={onClick} disabled={disabled}>
        <span className="icon-only">
          {children}
        </span>
        <div className="tooltip group-hover:opacity-100">
          <span></span>
          {label}
        </div>
      </button>
    )
  }

  return (
    <Link to={href} className={`button rounded icon-only h-8 group ${buttonStyle} mr-1.5 hover:bg-opacity-90`}>
      <span className="icon-only">
        {children}
      </span>
      <div className="tooltip group-hover:opacity-100">
        <span></span>
        {label}
      </div>
    </Link>
  );
};

export default IconOnlyButton;