import Round from '../Round'

export default class RoundMap extends Map<string, Round> {
    protected _roundNumberToID: Map<number, string> = new Map()

    set(key: string, value: Round): this {
        if (this.has(key)) {
            // Delete for the purpose of clearing the old Agent UUID
            this.delete(key)
        }

        super.set(key, value)

        if (value.doc.physicalRoundOrder) {
            this._roundNumberToID.set(value.doc.physicalRoundOrder, key)
        }

        return this
    }

    delete(key: string): boolean {
        if (this.has(key)) {
            for (const i of Array.from(this._roundNumberToID.entries())) {
                if (key === i[1]) {
                    this._roundNumberToID.delete(i[0])
                    break
                }
            }
        }

        return super.delete(key)
    }

    hasRound(key: number): boolean {
        return this._roundNumberToID.has(key)
    }

    getByRound(key: number): Round | undefined {
        let value
        if ((value = this._roundNumberToID.get(key))) {
            return this.get(value) as Round
        }

        return undefined
    }
}
