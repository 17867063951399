import Participant from './Participant'
import Round from './Round'
import Tournament from './Tournament'
import { RoundLevel, RoundType, TournamentMatch } from '../../../../providers/tournaments/tournaments.provider'

export default class Match {
    public readonly doc: TournamentMatch
    public readonly tournament: Tournament
    public readonly round: Round
    private _teamOne: null | Participant = null
    private _teamTwo: null | Participant = null
    private _winnerPath: null | Match = null
    private _loserPath: null | Match = null

    public get teamOne() {
        return this._teamOne
    }

    public set teamOne(value: Participant | null) {
        if (value) {
            this.doc.teamOne = value.doc._id
        } else {
            this.doc.teamOne = undefined
        }

        this._teamOne = value
    }

    public get teamTwo() {
        return this._teamTwo
    }

    public set teamTwo(value: Participant | null) {
        if (value) {
            this.doc.teamTwo = value.doc._id
        } else {
            this.doc.teamTwo = undefined
        }

        this._teamTwo = value
    }

    public get winnerPath() {
        return this._winnerPath
    }

    public set winnerPath(value: Match | null) {
        if (value !== null) {
            this.doc.winnerPath = value.doc._id
        } else {
            this.doc.winnerPath = undefined
        }

        this._winnerPath = value
    }

    public get loserPath() {
        return this._loserPath
    }

    public set loserPath(value: Match | null) {
        if (value !== null) {
            this.doc.loserPath = value.doc._id
        } else {
            this.doc.loserPath = undefined
        }

        this._loserPath = value
    }

    get shortName() {
        let round = `R${this.round.doc.sortRoundOrder}`
        if (this.round.doc.level === RoundLevel.LOWER) {
            round = `LBR${this.round.doc.sortRoundOrder - 1}`
        }

        if (this.round.doc.type === RoundType.FINALS) {
            round = '(Finals) ' + round
        } else if (this.round.doc.type === RoundType.SEMIS) {
            round = '(Semifinals) ' + round
        } else if (this.round.doc.type === RoundType.TIEBREAKER) {
            round = '(Tiebreaker) ' + round
        }

        return `${round}-M${this.doc.matchNumber}`
    }

    get longName() {
        let round = `Round #${this.round.doc.sortRoundOrder}`
        if (this.round.doc.level === RoundLevel.LOWER) {
            round = `Lower Bracket Round #${this.round.doc.sortRoundOrder - 1}`
        }

        if (this.round.doc.type === RoundType.FINALS) {
            round = '(Finals) ' + round
        } else if (this.round.doc.type === RoundType.SEMIS) {
            round = '(Semifinals) ' + round
        } else if (this.round.doc.type === RoundType.TIEBREAKER) {
            round = '(Tiebreaker) ' + round
        }

        return `${round} - Match #${this.doc.matchNumber}`
    }

    constructor(document: TournamentMatch, round: Round, tournament: Tournament) {
        this.doc = document
        this.round = round
        this.tournament = tournament
    }

    fillMatchData() {
        if (this.doc.teamOne) {
            const teamOne = this.tournament.participants.find(t => t.doc._id === this.doc.teamOne)
            this.teamOne = teamOne ?? null
        }

        if (this.doc.teamTwo) {
            const teamTwo = this.tournament.participants.find(t => t.doc._id === this.doc.teamTwo)
            this.teamTwo = teamTwo ?? null
        }

        if (this.doc.winnerPath) {
            const winnerPath = this.tournament.getMatches().find(m => m.doc._id === this.doc.winnerPath)
            this.winnerPath = winnerPath ?? null
        }

        if (this.doc.loserPath) {
            const loserPath = this.tournament.getMatches().find(m => m.doc._id === this.doc.loserPath)
            this.loserPath = loserPath ?? null
        }
    }
}
