import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Table } from "@tanstack/react-table";

type PageSizeSelectorProps<T> = {
  table: Table<T>;
  options: number[];
};

const PageSizeSelector = <T,>({ table, options }: PageSizeSelectorProps<T>) => {
  return (
    <div className="page-size-selector relative z-20 bg-white dark:bg-form-input">
      <select
        className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-2 pl-4 pr-12 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:focus:bg-form-input"
        value={table.getState().pagination.pageSize}
        onChange={(e) => {
          table.setPageSize(Number(e.target.value));
        }}
      >
        {options.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
      <span className="absolute top-1/2 right-4 z-10 -translate-y-1/2">
        <ChevronDownIcon className="w-6" />
      </span>
    </div>
  );
};

export default PageSizeSelector;