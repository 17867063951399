import axios from 'axios';
import { Snowflake } from 'discord-api-types/v10'

export interface IAutoModSettings {
  allowDiscordInvites?: Array<Snowflake>,
  spam?: {
    mentions?: number,
    content?: number,
    scam?: number,
  }
}

export type SettingsAutomodProviderType = {
  settings: IAutoModSettings | null;
  fetchSettings: () => Promise<{ settings: IAutoModSettings }>
  updateSettings: (formData: IAutoModSettings) => Promise<void>
}

const api = axios.create({
  baseURL: '/api/guild/settings',
  timeout: 30_000,
  headers: {
    'Content-Type': 'application/json'
  }
});

const automodProvider: SettingsAutomodProviderType = {
  settings: null,
  fetchSettings: async () => {
    const response = await api.get('/moderation')

    automodProvider.settings = response.data.settings;

    return response.data;
  },
  updateSettings: async (formData) => {
    await api.post('/moderation', {
      settings: formData
    });

    return;
  }
};

export default automodProvider;