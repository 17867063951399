import { BugAntIcon, CheckIcon, ExclamationTriangleIcon, QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import notificationProvider, { INotification, NotificationType } from "../../providers/notification.provider";

const styles = {
  [NotificationType.SUCCESS]: "bg-[#1EA779]",
  [NotificationType.DANGER]: "bg-[#7F1D1D]",
  [NotificationType.WARNING]: "bg-[#DB9C04]",
  [NotificationType.INFO]: "bg-[#3ABFF8]",
}

const icons = {
  [NotificationType.SUCCESS]: <CheckIcon className="w-8" />,
  [NotificationType.DANGER]: <BugAntIcon className="w-7" />,
  [NotificationType.WARNING]: <ExclamationTriangleIcon className="w-7" />,
  [NotificationType.INFO]: <QuestionMarkCircleIcon className="w-7" />,
}

const Notification = (props: INotification) => {
  const iconStyle = styles[props.type];
  const icon = icons[props.type];

  const removeNotification = () => {
    notificationProvider.removeNotification(props.id);
  }

  return (
    <div className="max-w-[422px] rounded-lg py-4 pl-4 pr-4.5 shadow-2 dark:bg-meta-4">
      <div className="flex items-center justify-between">
        <div className="flex flex-grow items-center gap-5">
          <div className="flex flex-grow items-center gap-5">
            <div className={`flex h-10 w-full max-w-10 items-center justify-center rounded-full ${iconStyle} text-white`}>
              {icon}
            </div>
            <div>
              <h4 className="mb-0.5 text-title-xsm font-medium text-black dark:text-white">
                {props.title}
              </h4>
              <p className="text-sm font-medium">
                {props.body}
              </p>
            </div>

            <div>
              <button onClick={removeNotification}>
                <XMarkIcon className="w-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;