import { TournamentRound } from '../../../../providers/tournaments/tournaments.provider'
import Match from './Match'
import Tournament from './Tournament'

export default class Round {
    public readonly doc: TournamentRound
    public readonly tournament: Tournament
    public matches: Match[] = []

    constructor(document: TournamentRound, tournament: Tournament) {
        this.doc = document
        this.tournament = tournament

        for (const match of this.doc.matches) {
            this.matches.push(new Match(match, this, tournament))
        }
    }
}
