interface NameValueListItemProps {
    label: string;
    value: string;
    textBreakClass?: string;
  }
  
  const NameValueListItem: React.FC<NameValueListItemProps> = ({
    label,
    value,
    textBreakClass,
  }) => {
    return (
      <li className="border-stroke dark:border-strokedark">
        <div className="px-2 py-2">
          <div className="flex items-center justify-between">
            <p className="text-black dark:text-white md:truncate">{label}</p>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className="sm:flex">
              <p
                className={`flex items-center font-light ${
                  textBreakClass ? textBreakClass : ""
                }`}
              >
                {value}
              </p>
            </div>
          </div>
        </div>
      </li>
    );
  };
  
  export default NameValueListItem;