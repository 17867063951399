import React from "react";
import Participant from "../bracket-viewer/data/Participant";

export interface StandingTableRowProps {
  player: Participant;
  position: number;
}

const StandingTableRowComponent: React.FC<StandingTableRowProps> = ({ player, position }) => {
  return (
    <div className="grid grid-cols-4 border-b border-stroke dark:border-strokedark">
      <div className="flex items-center gap-3 p-2.5 xl:p-5">
        <span
          className="max-w-6.5 flex h-6.5 w-full items-center justify-center rounded-full bg-primary text-white">
          {position + 1}
        </span>
        <p className="text-black dark:text-white">{player.doc.name}</p>
      </div>
      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{player.wins}</p>
      </div>
      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{player.losses}</p>
      </div>
      <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
        <p className="text-black dark:text-white">{player.draws}</p>
      </div>
    </div>
  )
}

export default StandingTableRowComponent;