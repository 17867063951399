import { useEffect } from "react";
import Tournament from "./data/Tournament";
import renderFn from "./utils/MainRender";

const BracketCanvas: React.FC<{ tournament: Tournament, overrideFill?: string }> = ({ tournament, overrideFill }) => {
  useEffect(() => {
    const canvas = document.getElementById('bracket-viewer') as HTMLCanvasElement;
    const context = canvas.getContext('2d');

    renderFn(tournament, canvas, context!, overrideFill)
  }, [overrideFill, tournament]);

  return (
    <canvas id="bracket-viewer" className="diagram-component" />
  );
};

export default BracketCanvas;