import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import tournamentProvider, { ITournament, TournamentStatus } from "../../../providers/tournaments/tournaments.provider";
import Loader from "../../../components/loader";
import { format } from "date-fns";
import NameValueListItem from "../../../components/data/key-value.list";
import {AcademicCapIcon, PencilIcon, ClockIcon, LockClosedIcon, RocketLaunchIcon, TrashIcon} from "@heroicons/react/24/outline";
import notificationProvider, { NotificationType } from "../../../providers/notification.provider";
import IconOnlyButton from "../../../components/buttons/icon-only.button";
import appProvider from "../../../providers/app.provider";
import authProvider from "../../../providers/auth.provider";

const TournamentViewPage = () => {
  let { id } = useParams<"id">();
  const [ tournament, setTournamentData ] = useState<ITournament | null>(null);
  const [ actionButtonDisabled, setActionButtonState ] = useState<boolean>(false);

  useEffect(() => {
    appProvider.setTitle('Tournaments / View / ..');

    tournamentProvider.fetchTournament(id as string)
      .then((data) => {
        setTournamentData(data);
        appProvider.setTitle(`Tournaments / View / ${data.name}`);
      });
  }, [id]);

  if (tournament === null) {
    return <Loader />;
  }

  const onRemoveElimination = (userId: string) => {
    tournamentProvider.removeElimination(id as string, userId)
      .then((data) => {
        setTournamentData(data);
        notificationProvider.addNotification({
          title: "Elimination Removed",
          type: NotificationType.SUCCESS,
          body: "Successfully removed user from the Elimination queue."
        });
      })
      .catch(err => {
        notificationProvider.addNotification({
          title: "An Error Occurred",
          type: NotificationType.DANGER,
          body: err.message
        });
      });
  }

  const onStartTournament = () => {
    setActionButtonState(true);
    tournamentProvider.startTournament(id as string)
      .then((data) => {
        setTournamentData(data);
        notificationProvider.addNotification({
          title: "Tournament Started",
          type: NotificationType.SUCCESS,
          body: "Successfully started the tournament."
        })
      })
      .catch(err => {
        notificationProvider.addNotification({
          title: "An Error Occurred",
          type: NotificationType.DANGER,
          body: err.message
        });
      })
      .finally(() => {
        setActionButtonState(false);
      });
  }

  const onCloseEliminationsEntry = () => {
    setActionButtonState(true);
    tournamentProvider.closeEliminationsEntry(id as string)
      .then((data) => {
        setTournamentData(data);
        notificationProvider.addNotification({
          title: "Tournament Queue Closed",
          type: NotificationType.WARNING,
          body: "This tournament can now no longer be joined."
        });
      })
      .catch(err => {
        notificationProvider.addNotification({
          title: "An Error Occurred",
          type: NotificationType.DANGER,
          body: err.message
        });
      })
      .finally(() => {
        setActionButtonState(false);
      });
  }

  const isJudge = tournament.people.find(person => person.userId === authProvider.user?.id && person.role === "judge");
  const isHost = tournament.people.find(person => person.userId === authProvider.user?.id && person.role === "host");
  const isAdmin = authProvider.user?.permissions.manageTournaments;
  const hasStarted = ![TournamentStatus.NOT_STARTED, TournamentStatus.ARCHIVED, TournamentStatus.FINISHED].includes(tournament.status);

  return (
    <>
      <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        <div className="col-span-12">
          <div className="flex justify-end text-sm">
            {(tournament.status === TournamentStatus.NOT_STARTED && isAdmin) &&
              <Link
                className="button h-8 rounded bg-primary-dark border-primary-dark p-3 ml-2 text-gray enabled:hover:bg-opacity-90 disabled:opacity-50"
                to={`/tournaments/${id}/edit`}
              >
                <PencilIcon className="h-4 w-4" /> Edit
              </Link>
            }

            {(tournament.status === TournamentStatus.NOT_STARTED && isHost) &&
              <button
                className="button h-8 rounded bg-primary border-primary p-3 ml-2 text-gray enabled:hover:bg-opacity-90 disabled:opacity-50"
                onClick={onStartTournament}
                disabled={actionButtonDisabled}
              >
                <RocketLaunchIcon className="h-4 w-4" /> Start Tournament
              </button>
            }

            {(tournament.status === TournamentStatus.STARTED && isHost) &&
              <button
                className="button h-8 rounded bg-info-dark border-info-dark p-3 ml-2 text-gray enabled:hover:bg-opacity-90 disabled:opacity-50"
                onClick={onCloseEliminationsEntry}
                disabled={actionButtonDisabled}
              >
                <LockClosedIcon className="h-4 w-4" /> Close Entries
              </button>
            }

            {(hasStarted && isJudge) &&
              <Link
                to={`/tournaments/${id}/judge`}
                className="button h-8 rounded bg-info-dark border-info-dark p-3 ml-2 text-gray enabled:hover:bg-opacity-90 disabled:opacity-50"
              >
                <AcademicCapIcon className="h-4 w-4" /> Judge Panel
              </Link>
            }

            {(hasStarted && isHost) &&
              <Link
                to={`/tournaments/${id}/host`}
                className="button h-8 rounded bg-info-dark border-info-dark p-3 ml-2 text-gray enabled:hover:bg-opacity-90 disabled:opacity-50"
              >
                <ClockIcon className="h-4 w-4" /> Host Panel
              </Link>
            }
          </div>
        </div>
      </div>
        
      <div className="mt-7.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        <div className="col-span-4">

          <div className="rounded-sm relative border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke px-4 py-4 dark:border-strokedark md:px-6 md:py-6 xl:px-7.5">
              <div className="flex items-start justify-between">
                <h2 className="text-title-sm2 self-center font-bold text-black dark:text-white">
                  Settings
                </h2>
              </div>
            </div>
            <div className="px-4 pt-4 md:px-6 md:py-6 xl:px-7.5">
              <div className="flex flex-col gap-6">
                <ul className="divide-y">
                  <NameValueListItem label="Created on" value={format(tournament.createdAt, 'Pp')} />
                  {tournament.startedAt && <NameValueListItem label="Started at" value={format(tournament.startedAt, 'Pp')} />}
                  {tournament.finishedAt && <NameValueListItem label="Ended at" value={format(tournament.finishedAt, 'Pp')} />}
                  <NameValueListItem label="Has a 3rd place match?" value={tournament.thirdPlaceMatch ? 'Yes' : 'No'} />
                  <NameValueListItem label="Maximum participants? (Top 8/16/etc)" value={tournament.maxParticipants.toString()} />
                </ul>
              </div>
            </div>
            <div className="px-4 pt-4 md:px-6 md:py-6 xl:px-7.5">
              <div className="flex flex-col gap-6">
                <ul className="divide-y">
                  <NameValueListItem label="Hosts" value={tournament.people.filter(person => person.role === 'host').map(person => {
                    if (person.displayName ?? person.username) {
                      return `${person.displayName ?? person.username} (${person.userId})`;
                    }

                    return person.userId;
                  }).join('\n')} />
                  <NameValueListItem label="Judges" value={tournament.people.filter(person => person.role === 'judge').map(person => {
                    if (person.displayName ?? person.username) {
                      return `${person.displayName ?? person.username} (${person.userId})`;
                    }

                    return person.userId;
                  }).join('\n')} />
                </ul>
              </div>
            </div>
          </div>

        </div>
        

        <div className="col-span-5">

          <div className="rounded-sm relative border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke px-4 py-4 dark:border-strokedark md:px-6 md:py-6 xl:px-7.5">
              <div className="flex items-start justify-between">
                <h2 className="text-title-sm2 self-center font-bold text-black dark:text-white">
                  Elimination Queue ({tournament.eliminationQueue.length})
                </h2>
              </div>
            </div>
            <div className="flex flex-col gap-6 px-4 md:px-6 xl:px-7.5 pb-4">
              <ul className="divide-y">
                {tournament.eliminationQueue.map(user => {
                  return (
                    <li key={user._id} className="border-stroke dark:border-strokedark">
                      <div className="py-2">
                        <div className="flex items-center justify-between">
                          <p className="text-black dark:text-white md:truncate">{user.name} <small>({user.userId})</small></p>
                          {[TournamentStatus.STARTED, TournamentStatus.ELIMS_CLOSED].includes(tournament.status) && 
                            <IconOnlyButton
                              onClick={() => onRemoveElimination(user._id)}
                              label={`Remove ${user.name}`}
                              theme="warning"
                            >
                              <TrashIcon className="h-4 w-4" />
                            </IconOnlyButton>
                          }
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default TournamentViewPage;