import React from "react";
import {TournamentElimination} from "../../../providers/tournaments/tournaments.provider";
import EliminationStandingTableRowComponent from "./elimination-standing-table-row.component";

export interface EliminationsStandingsTableComponentProps {
  eliminations: TournamentElimination[];
}

const EliminationsStandingsTableComponent: React.FC<EliminationsStandingsTableComponentProps> = ({ eliminations }) => {
  eliminations.sort((a, b) => (b.finalScore ?? 0) - (a.finalScore ?? 0));

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-2 md:grid-cols-6 rounded-sm bg-gray-2 dark:bg-meta-4">
        <div className="p-2.5 xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base"># / Name</h5>
        </div>
        <div className="hidden md:block p-2.5 text-center xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">Originality</h5>
        </div>
        <div className="hidden md:block p-2.5 text-center xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">Musicality</h5>
        </div>
        <div className="hidden md:block p-2.5 text-center xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">Clarity</h5>
        </div>
        <div className="hidden md:block p-2.5 text-center xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">Complexity</h5>
        </div>
        <div className="p-2.5 text-center xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">Total</h5>
        </div>
      </div>

      {eliminations.map((player, i) => <EliminationStandingTableRowComponent player={player} key={`player_${i}`} position={i} />)}
    </div>
  );
}

export default EliminationsStandingsTableComponent;