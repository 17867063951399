import React, {memo} from "react";
import {XMarkIcon} from "@heroicons/react/24/solid";

const MatchResult: React.FC<{
  winnerId?: string,
  winnerName?: string,
  overtime?: boolean,
  onClick: () => void
}> = memo(({winnerId, winnerName, overtime, onClick}) => {
  return (
    <div
      className="absolute ml-[50%] -translate-x-1/2 w-full h-full rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark !bg-opacity-80 backdrop-blur-[8px] z-99">
      <button onClick={onClick}
              className="float-end flex m-2 justify-center rounded bg-primary p-1 enabled:hover:bg-opacity-90">
        <XMarkIcon className="w-6 h-6"/>
      </button>
      <div className="grid grid-cols-1 py-10 px-50 h-full justify-center text-center">
        {overtime && <p className="font-bold text-9xl content-center">Overtime ‍🔥</p>}
        {(!overtime && winnerName) &&
          <React.Fragment>
              <p className="font-bold text-8xl content-center">Winner</p>
              <p className="font-bold text-9xl text-primary content-center">{winnerName}</p>
              <small className='mt-3 text-neutral text-xl'>({winnerId})</small>
          </React.Fragment>
        }
      </div>
    </div>
  );
});

export default MatchResult;