import Match from "../data/Match";
import { MATCH } from "../styles";
import { ALIGNMENTS, VERTICAL_ALIGNMENTS } from "./Constants";
import roundRect from "./RoundRect";
import writeText from "./WriteText";

const renderMatch = async (match: Match) => {
  const matchCanvas = document.createElement('canvas');
  matchCanvas.width = MATCH.width + MATCH.padding.left;
  matchCanvas.height = MATCH.height;

  const matchCtx = matchCanvas.getContext('2d') as CanvasRenderingContext2D;
  const isMatchPlayed = !match.doc.active && (match.doc.teamOneWins > 0 || match.doc.teamTwoWins > 0)

  matchCtx.lineWidth = MATCH.lineWidth

  // Background
  matchCtx.fillStyle = MATCH.fill
  roundRect(
    matchCtx,
    (MATCH.lineWidth / 2) + MATCH.padding.left,
    (MATCH.lineWidth / 2),
    MATCH.width - MATCH.lineWidth,
    MATCH.height - MATCH.lineWidth,
    MATCH.radius,
    true,
  )

  // Player Scores
  matchCtx.fillStyle = (match.doc.teamOneWins > match.doc.teamTwoWins || match.doc.teamOneBye) ? MATCH.score.win.fill : MATCH.score.default.fill
  roundRect(
    matchCtx,
    MATCH.width + MATCH.padding.left + (MATCH.lineWidth / 2) - MATCH.score.width,
    (MATCH.lineWidth / 2),
    MATCH.score.width - MATCH.lineWidth,
    (MATCH.height / 2) - (MATCH.lineWidth / 2),
    {
      tr: MATCH.radius, br: 0, bl: 0, tl: 0
    },
    true
  )
  matchCtx.fillStyle = (match.doc.teamTwoWins > match.doc.teamOneWins || match.doc.teamTwoBye) ? MATCH.score.win.fill : MATCH.score.default.fill
  roundRect(
    matchCtx,
    MATCH.width + MATCH.padding.left + (MATCH.lineWidth / 2) - MATCH.score.width,
    (MATCH.height / 2),
    MATCH.score.width - MATCH.lineWidth,
    (MATCH.height / 2) - (MATCH.lineWidth / 2),
    {
      tr: 0, br: MATCH.radius, bl: 0, tl: 0
    },
    true
  )

  matchCtx.strokeStyle = MATCH.player.stroke
  // Player 1 Border
  roundRect(
    matchCtx,
    (MATCH.lineWidth / 2) + MATCH.padding.left,
    (MATCH.lineWidth / 2),
    MATCH.width - MATCH.lineWidth,
    (MATCH.height / 2) - (MATCH.lineWidth / 2),
    {
      tr: MATCH.radius, br: 0, bl: 0, tl: MATCH.radius
    }
  )
  // Player 2 Border
  roundRect(
    matchCtx,
    (MATCH.lineWidth / 2) + MATCH.padding.left,
    (MATCH.height / 2),
    MATCH.width - MATCH.lineWidth,
    (MATCH.height / 2) - (MATCH.lineWidth / 2),
    {
      tr: 0, br: MATCH.radius, bl: MATCH.radius, tl: 0
    }
  )

  await writeText({
    font: MATCH.player.font,
    colour: MATCH.player.color,
    x: MATCH.player.margin.left + MATCH.padding.left,
    y: (MATCH.height / 4),
    alignment: ALIGNMENTS.LEFT,
    verticalAlignment: VERTICAL_ALIGNMENTS.MIDDLE
  }, match.teamOne?.doc.name ?? '-', matchCtx)

  if (isMatchPlayed) {
    await writeText({
      font: match.doc.teamOneWins > match.doc.teamTwoWins ? MATCH.score.win.font : MATCH.score.default.font,
      colour: match.doc.teamOneWins > match.doc.teamTwoWins ? MATCH.score.win.color : MATCH.score.default.color,
      x: MATCH.width + MATCH.padding.left - (MATCH.score.width / 2),
      y: (MATCH.height / 4),
      alignment: ALIGNMENTS.MIDDLE,
      verticalAlignment: VERTICAL_ALIGNMENTS.MIDDLE
    }, match.doc.teamOneWins.toString(), matchCtx)
  }

  await writeText({
    font: MATCH.player.font,
    colour: MATCH.player.color,
    x: MATCH.player.margin.left + MATCH.padding.left,
    y: (MATCH.height / 4) * 3,
    alignment: ALIGNMENTS.LEFT,
    verticalAlignment: VERTICAL_ALIGNMENTS.MIDDLE
  }, match.teamTwo?.doc.name ?? '-', matchCtx)

  if (isMatchPlayed) {
    await writeText({
      font: match.doc.teamTwoWins > match.doc.teamOneWins ? MATCH.score.win.font : MATCH.score.default.font,
      colour: match.doc.teamTwoWins > match.doc.teamOneWins ? MATCH.score.win.color : MATCH.score.default.color,
      x: MATCH.width + MATCH.padding.left - (MATCH.score.width / 2),
      y: (MATCH.height / 4) * 3,
      alignment: ALIGNMENTS.MIDDLE,
      verticalAlignment: VERTICAL_ALIGNMENTS.MIDDLE
    }, match.doc.teamTwoWins.toString(), matchCtx)
  }

  await writeText({
    font: MATCH.matchOrder.font,
    colour: MATCH.matchOrder.color,
    x: (MATCH.padding.left / 2),
    y: (MATCH.height / 2),
    alignment: ALIGNMENTS.MIDDLE,
    verticalAlignment: VERTICAL_ALIGNMENTS.MIDDLE
  }, match.doc.playOrder.toString(), matchCtx)

  return matchCanvas
}

export default renderMatch;