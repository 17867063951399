import React, {useMemo} from "react";
import { ITournament } from "../../../../providers/tournaments/tournaments.provider";
import SocketConnection from "../../../../providers/tournaments/tournament.socket.provider";
import BracketCanvas from "../../../../components/tournaments/bracket-viewer/bracket-canvas.component";
import Tournament from "../../../../components/tournaments/bracket-viewer/data/Tournament";
import { ArrowPathIcon, FireIcon } from "@heroicons/react/24/solid";
import { protocol } from "@remix/websocket-protocols";
import authProvider from "../../../../providers/auth.provider";
import JudgeVoteButton from "../../../../components/buttons/judge-vote-button";
import notificationProvider, {NotificationType} from "../../../../providers/notification.provider";

interface IProps {
  ws: SocketConnection;
  tournament: ITournament;
}

const TournamentBracketView: React.FC<IProps> = ({ ws, tournament }) => {
  const EliminationTournament = useMemo(() => {
    return new Tournament(tournament)
  }, [tournament]);

  const activeMatches = EliminationTournament.activeMatches();
  let activeMatch = null;

  if (activeMatches.length > 0) {
    activeMatch = activeMatches[0];
  }

  const handleVote = (teamOne: boolean, teamTwo: boolean, overtime: boolean) => {
    if (!teamOne && !teamTwo && !overtime) {
      notificationProvider.addNotification({
        title: "Voting Error",
        type: NotificationType.DANGER,
        body: "You must select a winner, or overtime."
      });
      return;
    }

    ws.send(protocol.C2S_Tournament_Match_Vote, {
      tournamentId: tournament.id,
      userId: authProvider.user!.id,
      teamOne,
      teamTwo,
      overtime,
    });
  }

  let currentVote;
  let isOvertime = false;

  if (activeMatch) {
    if (activeMatch.doc.draws > activeMatch.doc.teamOneWins + activeMatch.doc.teamTwoWins) {
      isOvertime = true;
    }

    if (!isOvertime) {
      const myVote = activeMatch.doc.votes.find((vote) => vote.judgeId === authProvider.user!.id);

      if (myVote) {
        currentVote = myVote;
      }
    } else {
      const myVote = activeMatch.doc.drawVotes.find((vote) => vote.judgeId === authProvider.user!.id);

      if (myVote) {
        currentVote = myVote;
      }
    }
  }

  return (
    <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
      {activeMatch &&
        <div className="col-span-12">

          <div className="rounded-sm relative border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke px-4 py-4 dark:border-strokedark md:px-6 md:py-6 xl:px-7.5">
              <h2 className="text-title-sm2 text-center font-bold text-black dark:text-white">
                {activeMatch?.longName}
              </h2>
            </div>
            <div className="grid grid-cols-3 gap-6 px-4 pt-4 pb-8 text-center font-bold text-4xl items-center">
              <div>{activeMatch?.teamOne?.doc.name}</div>
              <div className="text-8xl">vs</div>
              <div>{activeMatch?.teamTwo?.doc.name}</div>
            </div>

            <div className="grid grid-cols-3 gap-6 px-4 pt-4 pb-8 justify-items-center">
              <div>
                <JudgeVoteButton label="Vote" onClick={() => handleVote(true, false, false)} hasVoted={currentVote?.teamOne ?? false}>
                  <FireIcon className="w-6 h-6 mr-2" />
                </JudgeVoteButton>
              </div>
              <div>
                {!isOvertime &&
                  <JudgeVoteButton label="Overtime" onClick={() => handleVote(false, false, true)} theme="info" hasVoted={currentVote?.draw ?? false}>
                    <ArrowPathIcon className="w-6 h-6 mr-2" />
                  </JudgeVoteButton>
                }
              </div>
              <div>
                <JudgeVoteButton label="Vote" onClick={() => handleVote(false, true, false)} hasVoted={currentVote?.teamTwo ?? false}>
                  <FireIcon className="w-6 h-6 mr-2" />
                </JudgeVoteButton>
              </div>
            </div>
          </div>

        </div>
      }

      <div className="col-span-12 pb-6">
        <BracketCanvas tournament={EliminationTournament} />
      </div>
    </div>
  );
}

export default TournamentBracketView;