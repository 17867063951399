interface LogoProps {
  className?: string
}

const BbxShackLogo: React.FC<LogoProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={props.className} viewBox="0 0 5200 2770">
      <g fill="currentColor">
        <path d="M1687 2543c-6-10 21-794 27-800 3-3 61-2 129 2l124 7 6 182c4 99 10 279 13 399l7 217h-151c-83 0-153-3-155-7z"/>
        <path d="M2576 2541c-8-12-25-484-26-668v-133h741l-7 243c-4 133-9 312-13 397l-6 155-106 3c-58 1-118-2-132-7-26-10-26-10-29-138l-3-128-53-6c-29-4-56-9-58-12-3-3-2-37 2-76l7-71h97v-111l-72 3-73 3-7 269c-5 148-9 270-10 271-11 8-248 14-252 6z"/>
        <path d="M3415 2540c-3-5-1-134 5-286 5-152 10-325 10-383s4-111 8-117c5-9 97-9 367 1 198 7 362 13 364 14 6 1-8 243-14 250-3 3-105 7-225 9l-220 4v107c0 58 4 111 8 117 4 7 81 14 211 18 112 4 208 9 213 12 4 3 8 60 8 128v123l-262 6c-360 9-466 9-473-3z"/>
        <path d="M4285 2539c-6-9-4-100 14-619l6-175 125 3c69 2 128 6 132 10 8 9 31 770 23 782-9 14-292 13-300-1z"/>
        <path d="M2121 2408c3-111 1-133-11-135-8-1-32-4-52-8l-38-6v-228l55-6c30-4 56-8 56-9 1 0 4-62 8-136l6-135 120-6c66-3 124-3 128 1 9 8 39 779 31 792-3 5-73 8-155 8h-151l3-132z"/>
        <path d="M4720 2405v-135h-34c-66 0-66-1-66-125v-114l54-6 53-7 6-130c3-72 9-134 14-138 4-5 62-10 128-11l120-4v332l-39 47-39 48 51 51 50 52 4 135 3 135-152 3-153 3v-136z"/>
        <path d="M937 2523c-4-3-7-60-7-126v-119l183 3 182 4v-70l-181-3-182-2-7-133c-3-72-5-186-3-252l3-120 180 4c99 2 245 7 325 10l145 6 3 118 3 117h-119c-66 0-147 3-181 6-61 7-61 7-61 39 0 21 6 35 16 39 9 3 90 6 180 6h164l-2 238-3 237-316 3c-173 1-318-1-322-5z"/>
        <path d="M285 2458c-25-62-74-226-81-268-5-30 2-56 35-129 57-130 58-139 15-261-26-76-37-128-41-190-6-90-2-102 84-315l20-50-70-163c-68-159-69-163-51-183 11-12 28-19 43-17 21 2 37 22 83 103 71 124 97 191 105 269 6 70-15 146-73 258-19 37-34 74-34 83s18 71 40 139c22 67 40 136 40 151 0 16-21 73-46 128-57 121-56 142 9 314 26 67 47 128 47 136 0 14-54 37-87 37-15 0-26-12-38-42z"/>
        <path d="M503 2418c-42-45-58-106-58-218v-105l44-73c64-106 64-135 6-266-45-101-47-108-41-170 5-37 2-97-5-143-12-78-11-81 19-174 39-114 48-178 33-208-7-12-32-44-56-71-25-28-45-59-45-69 0-21 40-61 62-61 25 0 111 101 148 173l34 65-23 123c-12 68-33 171-47 229-13 58-24 115-24 128s23 82 51 155 51 148 52 168c1 25-15 69-51 143-60 125-64 157-30 273l22 76-28 23c-33 29-37 30-63 2z"/>
        <path d="M4054 1545c-4-8-4-25 0-38 3-12-4-57-16-101-19-68-23-76-32-57-7 15-6 41 2 80 18 84 16 91-16 91-15 0-37-7-49-15-17-12-20-22-16-58 4-31-4-77-26-155-17-62-34-130-37-152-4-25-12-40-21-40-12 0-13-11-7-57 11-89 18-101 80-130 69-31 163-44 274-36 60 4 105 0 153-11 38-9 89-16 115-16 35 0 52-5 65-20 13-16 29-20 79-20 80 0 172 22 209 49 35 26 37 26 33-1-2-13-5-72-8-131-4-91-3-107 9-97 7 6 18 34 25 63 39 177 56 239 82 294 29 60 30 83 5 83-9 0-11 30-8 115 3 99 1 115-12 115-22 0-37-59-37-145-1-107-45-189-89-166-10 6-33 8-49 5-36-7-44 3-53 71-4 28-14 84-24 125-19 87-13 134 37 259 16 41 27 80 24 88-8 19-86 13-96-7-4-8-17-49-29-90-26-88-51-150-61-150s-20 37-20 71c0 14 9 52 20 84s20 69 20 82c0 21-4 24-32 21-44-4-62-43-86-186-11-62-22-115-25-118-10-10-222 37-254 57-53 33-65 94-36 186 15 47 15 56 3 64-25 15-90 11-96-6z"/>
        <path d="m3059 1444-77-77-27 42-28 41h-153c-98 0-155-4-159-11-4-6 29-70 74-143 45-74 81-137 81-142 0-4-46-51-102-105l-103-97 115-111c63-61 118-111 121-111s36 29 72 65 70 65 76 65c5 0 12-8 16-19 3-10 17-31 30-45 24-26 26-26 167-26 79 0 149 4 156 8 9 6-4 36-54 118-85 140-104 178-96 190 4 6 48 45 99 88 51 42 95 81 98 87 4 5-33 64-82 132-114 158-117 158-224 51zm-190-141c68-111 97-153 105-153 5 0 53 43 107 95 89 87 119 108 119 88 0-5-48-54-107-109l-108-101 22-44c13-24 41-73 63-108 22-36 40-71 40-80 0-23-16-3-79 100-31 51-61 94-67 96-7 2-50-33-98-77-47-45-91-80-97-78s32 47 86 99l97 95-66 106c-36 58-66 108-66 112 0 20 24-1 49-41z"/>
        <path d="M917 1443c-4-3-7-152-7-330V790l24-10c14-5 150-8 304-7l278 2 41 27c87 57 115 156 68 242l-26 49 30 31c38 39 51 75 51 137 0 52-24 112-56 140-54 48-66 49-392 49-170 0-312-3-315-7zm518-97c41-18 75-69 75-114 0-40-23-88-50-102-19-10-19-12 10-52 49-68 38-140-29-185-32-22-44-23-202-23h-169v490h165c122 0 175-4 200-14z"/>
        <path d="M1090 1224v-105l170 3 170 3 29 30c45 46 45 102 2 146l-29 29h-342v-106zm224 20c9-3 16-16 16-28 0-22-33-66-50-66-4 0-15-3-24-6-13-5-16 3-16 50 0 55 0 56 29 56 16 0 36-3 45-6z"/>
        <path d="M1090 1001V900h332l29 29c38 39 40 97 5 138l-24 28-171 3-171 3v-100zm218 63c45-31 19-84-42-84-24 0-26 3-26 50 0 45 2 50 23 50 12 0 33-7 45-16z"/>
        <path d="M1750 1115V780l25-6c14-3 148-5 298-3 264 4 273 5 310 28 91 56 120 154 73 245l-25 50 23 21c100 93 73 256-51 319-27 14-77 16-343 16h-310v-335zm520 230c45-23 70-62 70-109 0-43-29-102-55-112-12-5-9-13 15-42 55-67 36-158-40-197-22-12-68-15-195-15h-165v490h170c131 0 178-3 200-15z"/>
        <path d="M1920 1225v-105h155c177 0 214 9 240 60 23 44 13 92-26 124-30 26-33 26-200 26h-169v-105zm224 19c10-4 16-18 16-35 0-29-22-50-67-64-22-6-23-4-23 49 0 55 0 56 29 56 16 0 36-3 45-6z"/>
        <path d="M1920 1000V900h330l32 29c27 24 33 36 33 71s-6 47-33 71l-32 29h-330v-100zm226 57c44-39 18-76-53-77-21 0-23 5-23 50 0 46 2 50 24 50 14 0 37-10 52-23z"/>
        <path d="M3575 1160c-4-6-11-24-15-38-8-27-8-27-9 6-2 69-51 11-51-61 0-11-9-21-21-24-16-4-19-12-17-37 3-27 8-33 48-44 25-7 74-22 110-34 106-34 180-7 180 65 0 17 4 47 9 65 15 51-5 37-33-23-23-52-38-66-50-47-3 5 6 32 20 61 34 75 32 100-8 64-17-15-18-15-18 6 0 35-31 22-45-18-21-64-68-47-63 23 2 31-1 41-14 44-9 2-19-2-23-8z"/>
        <path d="M4825 548c-2-7-5-53-7-101-3-74-6-91-23-103-19-14-19-15-1-49 11-19 22-35 26-35s17 11 30 25c18 20 21 28 12 37-8 8-12 50-12 119 0 62-4 110-10 114-6 3-12 0-15-7z"/>
      </g>
    </svg>
  )
}

export default BbxShackLogo;