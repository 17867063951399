import { useEffect, useState } from "react";
import ReactSelect from "../../../components/select/react-select.component";
import notificationProvider, { NotificationType } from "../../../providers/notification.provider";
import { ActionMeta, MultiValue } from "react-select";
import permissionsProvider, { IPermission, IRole } from "../../../providers/settings/settings.permissions.provider";
import Loader from "../../../components/loader";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import appProvider from "../../../providers/app.provider";

const PermissionsPage = () => {
  const [ permissions, setPermissionsData ] = useState<IPermission[]>([]);
  const [ roles, setRolesData ] = useState<IRole[]>([]);
  const [ isSyncing, setIsSyncing ] = useState<boolean>(false);

  appProvider.setTitle('Settings / Permissions');
  
  useEffect(() => {
    permissionsProvider.fetchPermissionSettings()
      .then((data) => {
        setPermissionsData(data.permissions);
        setRolesData(data.roles);
      });
  }, []);

  if (permissions.length === 0 || roles.length === 0) {
    return <Loader />;
  }

  const roleOptions = roles
    .sort((a, b) => b.position - a.position)
    .map(role => {
        return {
        label: role.name,
        value: role.id 
        }
    })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    permissionsProvider.updatePermissionSettings(permissions)
      .then(() => {
        notificationProvider.addNotification({
          title: "Settings Saved",
          body: "Successfully updated permissions.",
          type: NotificationType.SUCCESS
        });
      });
  };

  const onUpdate = (newValue: unknown, action: ActionMeta<unknown>) => {
    let newPermissions = []
    for (const permission of permissions) {
      if (permission.key !== action.name) {
        newPermissions.push(permission);
        continue;
      }

      newPermissions.push({
        ...permission,
        roles: (newValue as MultiValue<any>).map(role => role.value)
      })
    }

    setPermissionsData(newPermissions)
  };

  const syncCommands = () => {
    setIsSyncing(true);
    permissionsProvider.syncCommands()
      .then(() => {
        notificationProvider.addNotification({
          title: "Synced Commands",
          body: "Commands successfully synced",
          type: NotificationType.SUCCESS
        })
      })
      .finally(() => {
        setIsSyncing(false);
      })
  }

  return (
    <div>
      <div className="mt-7.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        <div className="col-span-6">

          <div className="rounded-sm relative border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke px-4 py-4 dark:border-strokedark md:px-6 md:py-6 xl:px-7.5">
              <div className="flex items-start justify-between">
                <h2 className="text-title-sm2 self-center font-bold text-black dark:text-white">
                  Permissions
                </h2>
              </div>
            </div>
            <form action="#" onSubmit={handleSubmit}>
              <div className="p-6.5">
                {permissions.map(permission => {
                  return (
                    <div className="mb-4.5" key={permission.key}>
                      <label htmlFor={permission.key} className="mb-3 block text-sm font-medium text-black dark:text-white">
                        {permission.name}
                        <br />
                        <small>{permission.description}</small>
                      </label>
                      <ReactSelect
                        name={permission.key}
                        required={false}
                        isClearable
                        options={roleOptions}
                        placeholder="Select roles..."
                        value={permission.roles.map(roleId => roleOptions.find(role => role.value === roleId))}
                        onChange={onUpdate}
                        closeMenuOnSelect
                      />
                    </div>
                  )
                })}

                <button type="submit" disabled={permissions.length === 0} className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray enabled:hover:bg-opacity-90 disabled:opacity-50">
                  Save Settings
                </button>
              </div>
            </form>
          </div>

        </div>

        <div className="col-span-6">
          <div className="flex justify-end">
            <button onClick={syncCommands} disabled={isSyncing} className="flex justify-center rounded bg-secondary p-3 font-medium text-gray enabled:hover:bg-opacity-90 disabled:opacity-50">
              <ArrowPathIcon className="h-6 w-6 mr-2.5" /> Sync Commands
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionsPage;