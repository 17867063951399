import React from "react";

export type AppProviderType = {
  pageTitle: string;
  pageTitleHook: PageTitleHook | null,
  setPageTitleHook: (dispatch: PageTitleHook) => void;
  setTitle: (title: string) => void;
};

type PageTitleHook = React.Dispatch<React.SetStateAction<string>>;

const appProvider: AppProviderType = {
  pageTitle: '',
  pageTitleHook: null,
  setPageTitleHook: (dispatch) => {
    appProvider.pageTitleHook = dispatch;
  },
  setTitle(title: string) {
    appProvider.pageTitle = title;

    if (appProvider.pageTitleHook) {
      appProvider.pageTitleHook(appProvider.pageTitle);
    }
  }
};

export default appProvider;