import { MessageCoder, protocol } from '@remix/websocket-protocols';
import notificationProvider, { NotificationType } from '../notification.provider';

export type Handler<T extends new () => any> = (msg: Exclude<ConstructorParameters<T>[0], undefined>) => Promise<void> | void

interface HandlersType {
    [key: string]: Handler<any>[]
}

export default class SocketConnection {
  protected handlers: HandlersType = {};
  protected connection: WebSocket | null = null;
  protected connectionStarter!: (host: string, userId: string, token: string, port: number) => Promise<WebSocket>;

  protected _isReady = false;

  get isReady() {
    return this._isReady;
  }

  constructor() {
    /**
     * A promise to the open internal web socket connection
     * @type {Promise<WebSocket>}
     * @private
    */
    this.connectionStarter = (host: string, userId: string, token: string, port: number) => {
      return new Promise((res, rej) => {
        this.connection = new WebSocket(`ws://${host}:${port}/Server?user=${userId}&token=${token}`);
        this.connection.binaryType = 'arraybuffer';

        this.connection.onopen = () => {
          res(this.connection!);
          this._isReady = true;
          this.monitorConnection();
        };

        this.connection.onerror = event => {
          console.error('[SocketConnection] WebSocket error: ', event, this.connection);
          rej(event);
        };

        this.registerHandler(protocol.S2C_ConnectionRejected, () => {
          notificationProvider.addNotification({
            title: "Connection Failed",
            body: "Could not connect to the server, please try again",
            type: NotificationType.DANGER
          });
        });

        this.listen();
      });
    };
  }

  listen(): void {
    this.connection!.onmessage = async (msg) => {
      const {
        name, message
      } = await MessageCoder.decode(msg.data);

      const handlers = this.handlers[name];
      if (handlers !== undefined) {
        for (const handler of handlers) {
          handler(message);
        }
      }
    }
  }

  async start(host: string, userId: string, token: string, port: number) {
    let starter = this.connectionStarter;

    this.connectionStarter = () => {
      throw new Error('Connection already started! Restarting not supported!');
    };

    return starter(host, userId, token, port);
  }

  monitorConnection() {
    let interval = setInterval(() => {
      if (this.connection?.readyState === WebSocket.CLOSED || this.connection?.readyState === WebSocket.CLOSING) {
        // TODO: Some kind of event
        clearInterval(interval);
      }
    });
  }

  public registerHandler<T extends new () => any>(messageClass: T, handler: Handler<T>): void {
    try {
      let handlers = this.handlers[messageClass.name];
      if (handlers === undefined) {
        handlers = [];
        this.handlers[messageClass.name] = handlers;
      }
      handlers.push(handler)
    } catch (err: Error | any) {
      if (err.name === 'TypeError') {
        console.warn('[SocketConnection] Packet could not be registered.');
      }
      throw err;
    }
  }

  public unregisterHandler<T extends new () => any>(messageClass: T, handler: Handler<T>): void {
    try {
      let handlers = this.handlers[messageClass.name];

      if (handlers === undefined) {
        return;
      }

      handlers = handlers.filter(_handler => _handler.name !== handler.name);

      this.handlers[messageClass.name] = handlers;
    } catch (err: Error | any) {
      if (err.name === 'TypeError') {
        console.warn('[SocketConnection] Packet could not be unregistered.');
      }
      throw err;
    }
  }

  send<T extends new () => any>(messageClass: T, data: ConstructorParameters<T>[0]): void {
    console.log(`>>> SEND ${messageClass.name}`, data);
    this.connection?.send(
      MessageCoder.encode(messageClass.name, data!)
    );
  }
}
