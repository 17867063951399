import { LoaderFunctionArgs, redirect } from "react-router-dom";
import authProvider from "../../providers/auth.provider";

const protectedLoader = async ({ request }: LoaderFunctionArgs) => {
  if (!authProvider.hasCheckedAuthenticated) {
    await authProvider.fetchProfile();
  }

  if (!authProvider.isAuthenticated) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/?" + params.toString());
  }
  
  return null;
};

export default protectedLoader;