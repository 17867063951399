import { Column } from "@tanstack/react-table";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/solid";

type TableHeaderProps<T> = {
  title: string;
  column: Column<T>;
  children?: React.ReactNode;
};

const TableHeader = <T,>(props: TableHeaderProps<T>) => {
  return (
    <>
      {props.column.getCanSort() === true ? (
        <div className="h-full">
          <button
            className="cursor-pointer select-none flex items-center"
            onClick={() =>
              props.column.toggleSorting(
                props.column.getIsSorted() === "desc" ||
                  props.column.getIsSorted() === false
                  ? false
                  : true
              )
            }
          >
            {props.title}
            {{
              asc: (
                <ChevronUpIcon className="w-5 inline-flex flex-col space-y-[2px] ml-2" />
              ),
              desc: (
                <ChevronDownIcon className="w-5 inline-flex flex-col space-y-[2px] ml-2" />
              ),
              false: (
                <ChevronUpDownIcon className="w-5 inline-flex flex-col space-y-[2px] ml-2" />
              ),
            }[props.column.getIsSorted() as string] ?? null}
          </button>
          {props.children}
        </div>
      ) : (
        <div className="h-full">
          <span>{props.title}</span>
          {props.children}
        </div>
      )}
    </>
  );
};

export default TableHeader;