export const MATCH = {
  width: 335,
  height: 100,
  margin: {
    left: 60,
    right: 60,
    bottom: 30,
  },
  padding: { left: 45 },
  lineWidth: 4,
  radius: 4,
  fill: '#242424',
  player: {
    font: '30px Ubuntu',
    stroke: '#545454',
    color: '#dcdcdc',
    margin: { left: 15, }
  },
  score: {
    width: 50,
    font: '30px Ubuntu',
    win: {
      fill: '#bdc869',
      font: '30px Ubuntu',
      color: '#363636'
    },
    default: {
      fill: '#363636',
      font: '30px Ubuntu',
      color: '#dcdcdc'
    }
  },
  matchOrder: {
    font: '22px Ubuntu',
    color: '#dcdcdc',
  }
}

export const CANVAS = {
  x: 20,
  y: 20,
  fill: '#1a222c'
}

export const COLUMN = { width: MATCH.width + MATCH.padding.left + MATCH.margin.left + MATCH.margin.right }

export const HEADER = {
  height: 50,
  width: COLUMN.width - 4,
  margin: { bottom: 30 },
  lineWidth: 4,
  stroke: '#545454',
  fill: '#545454',
  text: {
    font: '30px Ubuntu',
    color: '#c4c4c4',
  }
}