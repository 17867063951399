import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/data-table.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Main from './router/pages/root/dashboard.page';
import protectedLoader from './router/util/protected.loader';
import authProvider from './providers/auth.provider';
import AutoModPage from './router/pages/settings/auto-mod/page';
import LoggingPage from './router/pages/settings/logging.page';
import PermissionsPage from './router/pages/settings/permissions.page';
import Loader from './components/loader';
import ErrorPage from './components/error/error.page';
import TournamentListPage from './router/pages/tournaments/list.page';
import TournamentViewPage from './router/pages/tournaments/view.page';
import TournamentJudgeViewPage from './router/pages/tournaments/judge-view.page';
import TournamentCreatePage from './router/pages/tournaments/create.page';
import TournamentHostViewPage from './router/pages/tournaments/host-view.page';
import TournamentEditPage from "./router/pages/tournaments/edit.page";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    element: <App />,
    loader: async () => {
      try {
        await authProvider.fetchProfile();
      } catch (e) {

      }
      
      return { user: authProvider.user };
    },
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Main />, 
      },
      {
        id: "settings",
        path: "/settings",
        loader: protectedLoader,
        children: [
          {
            id: "settings-automod",
            path: "auto-mod",
            element: <AutoModPage />
          },
          {
            id: "settings-logging",
            path: "logging",
            element: <LoggingPage />
          },
          {
            id: "settings-permissions",
            path: "permissions",
            element: <PermissionsPage />
          }
        ]
      },
      {
        id: "tournaments",
        path: "/tournaments",
        loader: protectedLoader,
        children: [
          {
            id: "tournaments-list",
            path: "",
            element: <TournamentListPage />
          },
          {
            id: "tournaments-create",
            path: "new",
            element: <TournamentCreatePage />
          },
          {
            id: "tournaments-view",
            path: ":id",
            element: <TournamentViewPage />,
          },
          {
            id: "tournaments-edit",
            path: ":id/edit",
            element: <TournamentEditPage />
          },
          {
            id: "tournaments-judge-view",
            path: ":id/judge",
            element: <TournamentJudgeViewPage />
          },
          {
            id: "tournaments-host-view",
            path: ":id/host",
            element: <TournamentHostViewPage />
          }
        ]
      }
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <div className="dark:bg-boxdark-2 dark:text-bodydark appcontainer">     
      <div className="flex h-screen overflow-hidden">
        <RouterProvider router={router} fallbackElement={<Loader />} />
      </div>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
