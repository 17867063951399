import React from "react";
import { ITournament } from "../../../../providers/tournaments/tournaments.provider";
import SocketConnection from "../../../../providers/tournaments/tournament.socket.provider";
import EliminationQueueUser from "../../../../components/tournaments/elimination-queue-user.component";


interface IProps {
  ws: SocketConnection;
  tournament: ITournament;
}

const TournamentEliminationsView: React.FC<IProps> = ({ ws, tournament }) => {
  return (        
    <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
      <div className="col-span-12">

        <div className="rounded-sm relative border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="border-b border-stroke px-4 py-4 dark:border-strokedark md:px-6 md:py-6 xl:px-7.5">
            <div className="grid grid-cols-2 items-start justify-between">
              <h2 className="text-title-sm2 self-center font-bold text-black dark:text-white">
                Elimination Queue ({tournament.eliminationQueue.length})
              </h2>
            </div>
          </div>
          <div className="flex flex-col gap-6 pb-4">
            <ul className="divide-y">
              <li className="border-stroke dark:border-strokedark py-2 px-4">
                <div className="grid grid-cols-6 gap-2 items-center justify-between text-black dark:text-white">
                  <div>User</div>
                  <div>Originality (out of 10)</div>
                  <div>Musicality (out of 10)</div>
                  <div>Clarity (out of 10)</div>
                  <div>Complexity (out of 10)</div>
                  <div>Votes made</div>
                </div>
              </li>
              {tournament.eliminationQueue.map((user, i) => <EliminationQueueUser key={user._id} tournamentId={tournament.id} user={user} isCurrent={(tournament.eliminationPosition % tournament.eliminationQueue.length) === i} ws={ws} />)}
            </ul>
          </div>
        </div>

      </div>
    </div>
  );
}

export default TournamentEliminationsView;