import React from "react";
import { protocol } from '@remix/websocket-protocols';
import tournamentProvider, { ITournament } from "../../../../providers/tournaments/tournaments.provider";
import SocketConnection from "../../../../providers/tournaments/tournament.socket.provider";
import { ForwardIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, CheckIcon, XCircleIcon } from "@heroicons/react/24/solid";
import TimerComponent from "../../../../components/tournaments/timer.component";


interface IProps {
  ws: SocketConnection;
  tournament: ITournament;
}

const TournamentHostEliminationsView: React.FC<IProps> = ({ ws, tournament }) => {
  const judgeCount = tournamentProvider.judgeCount;

  const onSkipHandler = () => {
    const currentUser = tournament.eliminationQueue[tournament.eliminationPosition % tournament.eliminationQueue.length];

    ws.send(protocol.C2S_Tournament_SkipElim, { tournamentId: tournament.id, userId: currentUser._id });
  }

  const onFinishHandler = () => {
    ws.send(protocol.C2S_Tournament_EndEliminations, { tournamentId: tournament.id });
  }

  let allElimsJudged = true;

  for (const elim of tournament.eliminationQueue) {
    if (elim.results.length !== judgeCount) {
      allElimsJudged = false;
    }
  }

  const isLastElim = tournament.eliminationPosition >= tournament.eliminationQueue.length - 1;
  const currentUser = tournament.eliminationQueue[tournament.eliminationPosition % tournament.eliminationQueue.length];

  return (        
    <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
      <div className="col-span-12">

        <div className="rounded-sm relative border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">

          <TimerComponent ws={ws} />

          <div className="grid grid-cols-1 pt-3 px-4">
            <div className="flex justify-end">
              {isLastElim && 
                <button onClick={onFinishHandler} className="flex justify-center rounded bg-info p-3 py-2 mr-3 font-medium text-gray">
                  <CheckIcon className="w-6 h-6 mr-2" /> Finish
                </button>
              }
              {(!isLastElim || !allElimsJudged) && 
                <button onClick={onSkipHandler} className="flex justify-center rounded bg-primary p-3 py-2 font-medium text-gray">
                  <ForwardIcon className="w-6 h-6 mr-2" /> Next
                </button>
              }
            </div>
          </div>
          <div className="grid grid-cols-12 px-4 md:px-6 md:pb-6 xl:px-7.5">
            <div className="col-span-3 border-r border-stroke dark:border-strokedark">
              <h3 className="text-3xl font-bold">Queue ({tournament.eliminationQueue.length})</h3>
              <div className="grid grid-row text-2xl w-100">
                {tournament.eliminationQueue.map((user, i) => {
                  const votesCast = user.results.filter(result => 
                    typeof result.clarity === 'number' &&
                    typeof result.complexity === 'number' &&
                    typeof result.musicality === 'number' &&
                    typeof result.originality === 'number' 
                  );

                  return <li key={user._id} className="grid grid-cols-12 content-center my-1 pb-1 border-stroke dark:border-bodydark border-dashed border-b">
                    <span className="col-span-1">{i + 1}.&nbsp;</span>
                    <span className="col-span-9">{user.name}</span>
                    <span className="col-span-2 place-self-end text-sm">({votesCast.length}/{judgeCount}) {(votesCast.length === judgeCount) ? <CheckCircleIcon className="inline w-6 h-6 text-success" /> : <XCircleIcon className="inline w-6 h-6 text-danger" />}</span>
                  </li>;
                })}
              </div>
            </div>
            <div className="col-span-9 flex justify-center">
              <h1 className="w-auto self-center font-bold text-7xl">{currentUser.name}</h1>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default TournamentHostEliminationsView;