import React, { useState } from 'react';

import {
  Outlet,
  useRouteLoaderData,
} from "react-router-dom";

import Sidebar from './components/sidebar/sidebar';
import Header from './components/header/header';
import LoginPage from './router/pages/root/login.page';
import { AuthUser } from './providers/auth.provider';
import NotificationContainer from './components/notifications/notification.container';

function App() {
  const { user } = useRouteLoaderData("root") as { user: AuthUser | null };
  const [sidebarOpen, setSidebarOpen] = useState(false);

  if (!user) {
    return (<LoginPage />)
  }

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark appcontainer">     
      <div className="flex w-screen h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <NotificationContainer />

          <main>
            <div className="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-10">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default App;
