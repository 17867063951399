import React, {memo} from "react";
import {TrophyIcon} from "@heroicons/react/24/solid";
import Participant from "../bracket-viewer/data/Participant";

export enum StandingPosition {
  FIRST = 0,
  SECOND = 1,
  THIRD = 2,
}

export interface StandingComponentProps {
  position: StandingPosition;
  player: Participant;
}

const CORNER_STYLE = {
  0: 'border rounded-t-3xl border-stroke',
  1: 'border rounded-l-3xl mt-8 border-stroke border-r-0',
  2: 'border rounded-r-3xl mt-8 border-stroke border-l-0',
};

const TEXT_STYLE = {
  0: 'text-meta-8',
  1: 'text-neutral-light',
  2: 'text-warning-dark',
};

const TITLE_TEXT = {
  0: '1st',
  1: '2nd',
  2: '3rd',
};

const StandingPositionComponent: React.FC<StandingComponentProps> = memo(({ position, player }) => {
  return (
    <div
      className={`${CORNER_STYLE[position]} relative bg-white dark:border-strokedark dark:bg-boxdark text-center`}>
      <div className="py-4 md:py-5">
        <h2 className={`flex justify-center text-title-xl font-bold ${TEXT_STYLE[position]}`}>
          <TrophyIcon className="h-12 w-12 mr-4"/> {TITLE_TEXT[position]}
        </h2>
      </div>

      <div className="p-4 text-title-lg">
        {player.doc.name}
      </div>
    </div>
  )
});

export default StandingPositionComponent;