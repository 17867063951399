import axios from 'axios';
import { Snowflake } from 'discord-api-types/v10'

export interface IPermission {
  key: string;
  name: string;
  description: string;
  roles: Snowflake[]
}

export interface IRole {
    id: Snowflake;
    position: number;
    name: string;
    colour: string;
}

export type SettingsPermissionsProviderType = {
  permissions: IPermission[];
  roles: IRole[];
  fetchPermissionSettings: () => Promise<{ permissions: IPermission[], roles: IRole[] }>
  updatePermissionSettings: (formData: Array<Pick<IPermission, 'key' | 'roles'>>) => Promise<void>
  syncCommands: () => Promise<void>
}

const api = axios.create({
  baseURL: '/api/guild',
  timeout: 30_000,
  headers: {
    'Content-Type': 'application/json'
  },
});

const permissionsProvider: SettingsPermissionsProviderType = {
  permissions: [],
  roles: [],
  fetchPermissionSettings: async () => {
    const response = await api.get('/permissions');

    permissionsProvider.permissions = response.data.permissions;
    permissionsProvider.roles = response.data.roles;

    return response.data;
  },
  updatePermissionSettings: async (formData) => {
    await api.post('/permissions', { permissions: formData });

    return;
  },
  syncCommands: async () => {
    await api.post('/permissions/sync', {}, { timeout: 90_000 });

    return;
  },
};

export default permissionsProvider;