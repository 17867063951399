import React, { useMemo, useState } from "react";
import { ITournament } from "../../../../providers/tournaments/tournaments.provider";
import BracketCanvas from "../../../../components/tournaments/bracket-viewer/bracket-canvas.component";
import Tournament from "../../../../components/tournaments/bracket-viewer/data/Tournament";
import StandingPositionComponent from "../../../../components/tournaments/standings/standing-position.component";
import useColorMode from "../../../../hooks/color-mode.hook";
import StandingsTableComponent from "../../../../components/tournaments/standings/standings-table.component";
import EliminationsStandingsTableComponent
  from "../../../../components/tournaments/standings/eliminations-standings-table.component";

interface IProps {
  tournament: ITournament;
}

const TournamentFinalisedView: React.FC<IProps> = ({ tournament }) => {
  const [openTab, setOpenTab] = useState(1);
  const [colorMode] = useColorMode();

  const activeClasses = "text-primary border-primary";
  const inactiveClasses = "border-transparent";

  const EliminationTournament = useMemo(() => {
    return new Tournament(tournament)
  }, [tournament]);

  const standings = EliminationTournament.standings(false);

  return (
    <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">

      <div className="col-span-12 grid grid-cols-3">
        <StandingPositionComponent position={1} player={standings[1]} />
        <StandingPositionComponent position={0} player={standings[0]} />
        <StandingPositionComponent position={2} player={standings[2]} />
      </div>

      <div className="col-span-12 pb-6">
        <div
          className="rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="mb-6 flex flex-wrap gap-5 border-b border-stroke dark:border-strokedark sm:gap-10">
            <button
              className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${
                openTab === 1 ? activeClasses : inactiveClasses
              }`}
              onClick={() => setOpenTab(1)}
            >
              Brackets
            </button>
            <button
              className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${
                openTab === 2 ? activeClasses : inactiveClasses
              }`}
              onClick={() => setOpenTab(2)}
            >
              Standings
            </button>
            <button
              className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${
                openTab === 3 ? activeClasses : inactiveClasses
              }`}
              onClick={() => setOpenTab(3)}
            >
              Eliminations
            </button>
          </div>

          <div>
            <div
              className={`leading-relaxed ${openTab === 1 ? "block" : "hidden"}`}
            >
              <BracketCanvas tournament={EliminationTournament} overrideFill={colorMode === 'dark' ? '#24303f' : '#ffffff'} />
            </div>
            <div
              className={`leading-relaxed ${openTab === 2 ? "block" : "hidden"}`}
            >
              <StandingsTableComponent standings={standings} />
            </div>
            <div
              className={`leading-relaxed ${openTab === 3 ? "block" : "hidden"}`}
            >
              <EliminationsStandingsTableComponent eliminations={tournament.eliminationQueue} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TournamentFinalisedView;