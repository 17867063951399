import React, {memo, ReactNode} from 'react';

const iconClasses = {
  primary: 'bg-[#9B51E0]/[0.08] text-[#9B51E0]',
  accent: 'bg-[#51B4B7]/[0.08] text-[#51B4B7]',
  danger: 'bg-[#FF6766]/[0.08] text-[#FF6766]',
  warning: 'bg-[#FBBD23]/[0.08] text-[#FBBD23]',
};

const textClasses = {
  primary: 'text-[#9B51E0]',
  accent: 'text-[#51B4B7]',
  danger: 'text-[#FF6766]',
  warning: 'text-[#FBBD23]',
}

interface CardDataStatsProps {
  title: string;
  total: string;
  color: keyof typeof iconClasses;
  children: ReactNode;
}

const CardDataStats: React.FC<CardDataStatsProps> = memo(({
  title,
  color,
  total,
  children,
}) => {
  return (
    <div className="flex justify-between rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex items-center gap-5.5">
        <div className={`flex h-15 w-14.5 items-center justify-center rounded-lg ${iconClasses[color]}`}>
            {children}
        </div>

        <div>
            <p className={`text-xl font-bold ${textClasses[color]}`}>{title}</p>
            <span className="font-medium">{total}</span>
        </div>
      </div>
    </div>
  );
});

export default CardDataStats;
