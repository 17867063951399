import { nanoid } from "nanoid";

export enum NotificationType {
  SUCCESS,
  DANGER,
  WARNING,
  INFO,
}

export interface INotification {
  id: string;
  title: string;
  body: string;
  type: NotificationType
}

type NotificationHook = React.Dispatch<React.SetStateAction<INotification[]>>;

export type NotifcationProviderType = {
  notifications: INotification[];
  hookNotificationUpdate: NotificationHook | null,
  setHookNotificationUpdate: (dispatch: NotificationHook) => void;
  addNotification: (notification: Omit<INotification, 'id'>, timeout?: number) => void;
  removeNotification: (id: string) => void;
}

const notificationProvider: NotifcationProviderType = {
  notifications: [],
  hookNotificationUpdate: null,
  setHookNotificationUpdate: (dispatch) => {
    notificationProvider.hookNotificationUpdate = dispatch;
  },
  addNotification: (notification, timeout = 30000) => {
    const id = nanoid();

    notificationProvider.notifications = [
      ...notificationProvider.notifications,
      {
        id,
        ...notification
      }
    ];

    if (notificationProvider.hookNotificationUpdate) {
      notificationProvider.hookNotificationUpdate(notificationProvider.notifications);
    }

    setTimeout(() => {
      notificationProvider.removeNotification(id);
    }, timeout);
  },
  removeNotification: (id) => {
    notificationProvider.notifications = notificationProvider.notifications.filter(
      notification => notification.id !== id
    );

    if (notificationProvider.hookNotificationUpdate) {
      notificationProvider.hookNotificationUpdate(notificationProvider.notifications);
    }
  }
};

export default notificationProvider;