import React from "react";
import ReactSelect from "../../../../components/select/react-select.component";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ActionMeta, OnChangeValue } from "react-select";
import { TournamentPeople } from "../../../../providers/tournaments/tournaments.provider";

interface Props {
  index: number;
  people: {label: string, value: string}[];
  person: TournamentPeople;
  onChange: (index: number, key: string, value: string | null) => void
  onRemove: (index: number) => void
}

const JudgeHostLine: React.FC<Props> = ({ people, person, onChange, onRemove, index }) => {
  const roleOptions = [ { label: "Judge", value: "judge" }, { label: "Host", value: "host" } ];
  const onUpdate = (event: OnChangeValue<any, false>, action: ActionMeta<unknown>) => {
    onChange(index, (action.name as string).replace(`_${index}`, ''), event === null ? null : event.value);
  };

  const userIdValue = person.userId !== null ? people.find((option) => option.value === person.userId) : null;
  const roleValue = person.role !== null ? roleOptions.find((option) => option.value === person.role) : null;

  return (
    <div className="grid grid-cols-10 gap-6 items-center pt-4">
      <div className="col-span-4">
        <ReactSelect
          name={`userId_${index}`}
          isMulti={false}
          required={true}
          isClearable
          options={people}
          placeholder="Select a person..."
          value={userIdValue}
          onChange={onUpdate}
          closeMenuOnSelect
        />
      </div>

      <div className="col-span-4">
        <ReactSelect
          name={`role_${index}`}
          isMulti={false}
          required={true}
          isClearable
          options={roleOptions}
          placeholder="Select their role..."
          value={roleValue}
          onChange={onUpdate}
          closeMenuOnSelect
        />
      </div>

      <div className="col-span-2">
        <button
          className="button h-8 rounded bg-danger border-danger p-3 text-gray enabled:hover:bg-opacity-90 disabled:opacity-50"
          onClick={() => onRemove(index)}
        >
          <TrashIcon className="h-4 w-4" /> Remove
        </button>
      </div>
    </div>
  );
}

export default JudgeHostLine;