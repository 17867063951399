import React, { useState } from "react";

interface IProps {
  userId: string;
  fieldName: string;
  defaultValue?: number;
  onInput: (value?: number) => void
}

const JudgeVoteInput: React.FC<IProps> = ({ userId, fieldName, defaultValue, onInput }) => {
  const [ errorClass, setErrorClass ] = useState<string>("");

  const handleInputEvent = (event: React.FormEvent<HTMLInputElement>) => {
    if (Number.isNaN(Number.parseFloat(event.currentTarget.value))) {
      setErrorClass("!border-danger");
      onInput();
      return;
    }

    const value = Number.parseFloat(event.currentTarget.value);

    if (value < 0 || value > 10) {
      setErrorClass("!border-danger");
      return;
    }

    setErrorClass("");

    onInput(value);
  };

  return (
    <input
      name={`${userId}-${fieldName}`}
      type="text"
      inputMode="numeric"
      pattern="[0-9.]*"
      required={true}
      defaultValue={defaultValue}
      onInput={handleInputEvent}
      className={`w-full rounded-lg border-[1.5px] border-stroke bg-white py-3 px-5 text-black outline-none focus:transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary ${errorClass}`}
    />
  );
}

export default JudgeVoteInput;