import "./index.css";

import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  getFilteredRowModel,
  ColumnDef,
  ColumnSort,
  SortingState,
  ColumnFiltersState,
  ColumnFilter,
} from "@tanstack/react-table";

import Pagination from "./Pagination";
import { useState } from "react";
import Filter from "./Filter";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { TableDataResponse } from "./Types/table-data-response.type";
import {
  TableDataFilterOptionsWithGlobalSearch,
  TableDataOptions,
  TableDataSort,
} from "./Types/table-data-options.type";
import notificationProvider, { NotificationType } from "../../providers/notification.provider";
import Loader from "../loader";

type TableProps<TData, TFilterOptions> = {
  columns: ColumnDef<TData, any>[];
  defaultPaginationState?: PaginationState;
  defaultSortingState?: ColumnSort[];
  defaultFilterState?: ColumnFilter[];
  globalFilter?: {
    value: string;
    dispatch: (value: string) => void;
  };
  children?: React.ReactNode;
  queryFn: (
    params: TableDataOptions<TData, TFilterOptions>
  ) => Promise<TableDataResponse<TData>>;
  uniqueResourceIdentifier: string;
};

const ReactQueryTable = <TData, TFilterOptions>({
  columns,
  defaultPaginationState,
  defaultSortingState,
  defaultFilterState,
  globalFilter,
  children,
  queryFn,
  uniqueResourceIdentifier,
}: TableProps<TData, TFilterOptions>) => {
  const [sorting, setSorting] = useState<SortingState>(
    defaultSortingState ?? []
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    defaultFilterState ?? []
  );
  const [pagination, setPagination] = useState<PaginationState>(() => ({
    pageIndex: defaultPaginationState?.pageIndex ?? 0,
    pageSize: defaultPaginationState?.pageSize ?? 10,
  }));

  const {
    data: { data = [], meta } = {}, //your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    // refetch,
  } = useQuery<TableDataResponse<TData>>({
    queryKey: [
      uniqueResourceIdentifier,
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      let sort: TableDataSort<TData>[] = [];
      let filters: TableDataFilterOptionsWithGlobalSearch<TData> = {};

      if (sorting) {
        sort = sorting.map((i) => {
          return {
            orderBy: i.id as keyof TData,
            order: i.desc === true ? "desc" : "asc",
          };
        });
      }

      if (columnFilters) {
        filters = columnFilters.reduce((accumulator, currentValue) => {
          const key: keyof TData = currentValue.id as keyof TData;
          accumulator[key] = currentValue.value as any;

          return accumulator;
        }, filters);
      }

      console.log(columnFilters);

      if (globalFilter?.value) {
        filters.globalSearch = globalFilter.value;
      }

      const params: TableDataOptions<TData, TFilterOptions> = {
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        sort,
        filters: filters as TFilterOptions,
      };

      return queryFn(params);
      //read our state and pass it to the API as query params
      //   fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    },
    placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
  });

  const pageCount = Math.ceil(
    (meta?.totalRecordCount ?? 0) / pagination?.pageSize
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination,
    },
    state: {
      pagination,
      sorting,
      columnFilters,
      globalFilter: globalFilter?.value,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: globalFilter?.dispatch,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    pageCount: pageCount,
    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
    manualSorting: true, //turn off built-in client-side sorting
  });

  if (isError) {
    notificationProvider.addNotification({
      type: NotificationType.DANGER,
      title: "Error",
      body: "Error loading data"
    })
  }

  return (
    <section className="data-table-common data-table-primary-left-column">
      {(isRefetching || isLoading) && <Loader />}
      {children}
      <table className="datatable-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize(),
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="datatable-pagination">
        <Pagination
          table={table}
          pageRange={3}
          pageOptions={table.getPageOptions()}
        >
          <Pagination.Goto table={table} options={[10, 20, 30, 40, 50]} />
        </Pagination>
      </div>
    </section>
  );
};

ReactQueryTable.Filter = Filter;

export default ReactQueryTable;